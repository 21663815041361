import React from 'react';
import { Rnd } from 'react-rnd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BlueLine } from '../../assets/icons';
import './TimelineAnimation.scss';
import { setActiveAnimation, setActiveLayer, setRightMenu } from '../../redux/slices/editorSession';
import { updateAnimation } from '../../redux/slices/template';

function TimelineAnimation({
  animation,
  animationIndex,
  onSetOpenedAnimations,
  onSetOpenedEffects,
  openedEffects,
  openedAnimations,
  onSetIsNestableDragDisabled,
  layer
}) {
  const dispatch = useDispatch();

  const { selectedFormatId, editorType, studio } = useSelector((state) => state.editorSession);
  const { activeAnimation } = studio;

  const effects = ['fadeTop', 'fadeBottom', 'pulse', 'makeVisible'];

  const animationNames = {
    playVideo: 'Play video',
    scale: 'Scale',
    opacity: 'Opacity',
    moveX: 'Move X', // Eventually deprecated
    moveY: 'Move Y', // Eventually deprecated
    moveXY: 'Move',
    resizeX: 'Resize Width', // Eventually deprecated
    resizeY: 'Resize Height', // Eventually deprecated
    resize: 'Resize',
    resizeXY: 'Resize XY',
    rotate: 'Rotate',
    skewX: 'Skew X', // Eventually deprecated
    skewY: 'Skew Y', // Eventually deprecated
    skew: 'Skew',
    flipX: 'Flip X',
    flipY: 'Flip Y',
    radius: 'Corner radius',
    background: 'Background',
    fadeTop: 'Fade Top',
    fadeBottom: 'Fade Bottom',
    pulse: 'Pulse',
    makeVisible: 'Visibility'
  };

  function handleChangeOpeneAnimationAndEffects() {
    if (effects.includes(animation.type) && !openedEffects.includes(animation.uuid)) {
      onSetOpenedEffects([...openedEffects, animation.uuid]);
    } else if (!effects.includes(animation.type) && !openedAnimations.includes(animation.uuid))
      onSetOpenedAnimations([...openedAnimations, animation.uuid]);
  }

  const handleUpdateAnimation = (value, target) => {
    dispatch(updateAnimation(selectedFormatId, target, value));
  };

  return (
    <div
      className='animation-wrapper'
      key={animation.uuid}
      style={{
        pointerEvents: editorType === 'banner' ? 'none' : 'default'
      }}>
      <div className='timeline-layer-light-line'>
        {editorType === 'banner' ? (
          <div
            style={{
              transform: `translate(${animation.time * 100}px, -6px)`,
              width: animation.duration * 100,
              height: 36
            }}>
            <BlueLine className='left animation-blue-line-icon' />
            <div
              className='animation'
              style={{
                ...(activeAnimation.uuid === animation.uuid && {
                  backgroundColor: 'rgba(71, 146, 226, 0.2)'
                })
              }}>
              <p className='text-small animation-name'> {animationNames[animation.type]}</p>
            </div>
            <BlueLine className='right animation-blue-line-icon' />
          </div>
        ) : (
          <Rnd
            scale={1}
            enableResizing={{
              top: false,
              right: true,
              bottom: false,
              left: true,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }}
            disableDragging={editorType === 'banner'}
            default={{
              x: animation.time * 100,
              y: -6,
              width: animation.duration * 100,
              height: 36
            }}
            position={{
              x: animation.time * 100,
              y: -6
            }}
            size={{
              width: animation.duration * 100,
              height: 36
            }}
            maxWidth={animation.maxDuration ? animation.maxDuration * 100 : undefined}
            bounds='parent'
            dragAxis='x'
            onDragStart={() => {
              onSetIsNestableDragDisabled(true);
              dispatch(setRightMenu(effects.includes(animation.type) ? 'effects' : 'animate'));
              dispatch(
                setActiveAnimation({
                  uuid: animation.uuid,
                  target: animation.target,
                  index: animationIndex
                })
              );
              dispatch(setActiveLayer(layer.uuid));
              handleChangeOpeneAnimationAndEffects();
            }}
            onDragStop={(e, position) => {
              onSetIsNestableDragDisabled(false);
              if (animation.time === (position.x / 100).toFixed(1)) {
                dispatch(setRightMenu(effects.includes(animation.type) ? 'effects' : 'animate'));
                dispatch(
                  setActiveAnimation({
                    uuid: animation.uuid,
                    target: animation.target,
                    index: animationIndex
                  })
                );
                dispatch(setActiveLayer(layer.uuid));
                handleChangeOpeneAnimationAndEffects();
              } else {
                handleUpdateAnimation(
                  {
                    ...animation,
                    time: (position.x / 100).toFixed(1)
                  },
                  animation.target
                );
              }
            }}
            onResizeStart={() => {
              onSetIsNestableDragDisabled(true);
              dispatch(setRightMenu(effects.includes(animation.type) ? 'effects' : 'animate'));
              dispatch(
                setActiveAnimation({
                  uuid: animation.uuid,
                  target: animation.target,
                  index: animationIndex
                })
              );
              dispatch(setActiveLayer(layer.uuid));
              handleChangeOpeneAnimationAndEffects();
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              onSetIsNestableDragDisabled(false);
              const duration =
                typeof animation.duration === 'number'
                  ? animation.duration
                  : parseFloat(animation.duration);

              handleUpdateAnimation(
                {
                  ...animation,
                  time: (position.x / 100).toFixed(1),
                  duration: (duration + delta.width / 100).toFixed(1)
                },
                animation.target
              );

              handleChangeOpeneAnimationAndEffects();
            }}>
            <BlueLine className='left animation-blue-line-icon' />
            <div
              className='animation'
              style={{
                ...(activeAnimation.uuid === animation.uuid && {
                  backgroundColor: 'rgba(71, 146, 226, 0.2)'
                })
              }}>
              <p className='text-small animation-name'> {animationNames[animation.type]}</p>
            </div>
            <BlueLine className='right animation-blue-line-icon' />
          </Rnd>
        )}
      </div>
    </div>
  );
}

TimelineAnimation.defaultProps = {};

TimelineAnimation.propTypes = {
  animation: PropTypes.object.isRequired,
  animationIndex: PropTypes.number.isRequired,
  onSetOpenedAnimations: PropTypes.func.isRequired,
  onSetOpenedEffects: PropTypes.func.isRequired,
  openedEffects: PropTypes.arrayOf(PropTypes.string).isRequired,
  openedAnimations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetIsNestableDragDisabled: PropTypes.func.isRequired,
  layer: PropTypes.object.isRequired
};
export default TimelineAnimation;

import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CloseIcon,
  TrashcanIcon,
  ScaleAnimationIcon,
  OpacityAnimationIcon,
  MoveXAnimationIcon,
  MoveYAnimationIcon,
  MoveAnimationIcon,
  ResizeHeightAnimationIcon,
  ResizeWidthAnimationIcon,
  ResizeAnimationIcon,
  RotateAnimationIcon,
  SkewXAnimationIcon,
  SkewYAnimationIcon,
  FlipXAnimationIcon,
  FlipYAnimationIcon,
  RadiusAnimationIcon,
  BackgroundAnimationIcon,
  PlayVideoAnimationIcon,
  FadeTopIcon,
  FadeBottomIcon,
  PulseIcon,
  EyeIcon
} from '../../assets/icons';
import { getPathById } from '../../assets/utils';
import AnimationForm from './RightMenuAnimationForm';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import './RightMenuAnimationSelection.scss';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import useAuthorization from '../../../utils/hooks/useAuthorization';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import { useDispatch, useSelector } from 'react-redux';
import { resetActiveAnimation, setActiveAnimation } from '../../redux/slices/editorSession';
import { deleteAnimation } from '../../redux/slices/template';
import { flat } from '../../../helpers';

function AnimationSelection(props) {
  const {
    isIncluded,
    showList,
    onSetShowList,
    animationTypes,
    openedAnimations,
    onSetOpenedAnimations,
    onAddAnimationEditor
  } = props;

  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const isAuthorized = useAuthorization();

  const { activeLayer, editorType, studio, selectedFormatId } = useSelector(
    (state) => state.editorSession
  );
  const { presentLayers, activeAnimation } = studio;

  const flatData = flat(presentLayers);

  const isAnimationAllowed = (anim, path, layers) => {
    const i = path[0];
    path.shift();

    if (path.length > 0) {
      return isAnimationAllowed(anim, path, layers[i].layers);
    }
    if (layers[i]) {
      // background animation is allowed only for solid backgrund
      if (
        (anim.id !== 'background' && anim.allowedLayerTypes.includes(layers[i].type)) ||
        (anim.id === 'background' &&
          anim.allowedLayerTypes.includes(layers[i].type) &&
          (layers[i].format.background_color?.colorType === 'solid' ||
            layers[i].format.background_color === undefined))
      ) {
        return true;
      }
    }
  };

  const activeAnimationRef = useRef();

  useEffect(() => {
    if (activeAnimationRef && activeAnimationRef.current) {
      activeAnimationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, [activeAnimation]);
  const currentLayer = flatData.find((layer) => layer.id === activeLayer);
  const ANIMATION_TYPES_DATA = [
    {
      label: 'Scale',
      icon: ScaleAnimationIcon,
      id: 'scale',
      settings: { scale: 100, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Opacity',
      icon: OpacityAnimationIcon,
      id: 'opacity',
      settings: { opacity: 0 },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Move X',
      icon: MoveXAnimationIcon,
      id: 'moveX',
      settings: { left: 0 },
      ease: 'none',
      allowedLayerTypes: []
    },
    {
      label: 'Move Y',
      icon: MoveYAnimationIcon,
      id: 'moveY',
      settings: { top: 0 },
      ease: 'none',
      allowedLayerTypes: []
    },
    {
      label: 'Move',
      icon: MoveAnimationIcon,
      id: 'moveXY',
      settings: { top: 0, left: 0 },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Resize X',
      icon: ResizeWidthAnimationIcon,
      id: 'resizeX',
      settings: { width: 0 },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Resize Y',
      icon: ResizeHeightAnimationIcon,
      id: 'resizeY',
      settings: { height: 0 },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Resize',
      icon: ResizeAnimationIcon,
      id: 'resize',
      settings: { width: 0, height: 0 },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'image', 'video']
    },
    {
      label: 'Resize XY',
      icon: ResizeAnimationIcon,
      id: 'resizeXY',
      // set the layer default widht and height as the settings instead of 0
      settings: { width: currentLayer.format.width, height: currentLayer.format.height },
      ease: 'none',
      allowedLayerTypes: isAuthorized({ featureFlag: 'resize-x-y-animation' })
        ? ['rectangle', 'circle', 'image', 'video']
        : []
    },
    {
      label: 'Rotate',
      icon: RotateAnimationIcon,
      id: 'rotate',
      settings: { rotation: 0, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Skew X',
      icon: SkewXAnimationIcon,
      id: 'skewX',
      settings: { skewX: 0, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Skew Y',
      icon: SkewYAnimationIcon,
      id: 'skewY',
      settings: { skewY: 0, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Skew',
      icon: SkewXAnimationIcon,
      id: 'skew',
      settings: { skewX: 0, skewY: 0, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Flip X',
      icon: FlipXAnimationIcon,
      id: 'flipX',
      settings: { rotationX: 0, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Flip Y',
      icon: FlipYAnimationIcon,
      id: 'flipY',
      settings: { rotationY: 0, transformOrigin: 'center center' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Radius',
      icon: RadiusAnimationIcon,
      id: 'radius',
      settings: { borderRadius: 0 },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'image', 'video']
    },
    {
      label: 'Background',
      icon: BackgroundAnimationIcon,
      id: 'background',
      settings: { background: '' },
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'video']
    },
    {
      label: 'Play Video',
      icon: PlayVideoAnimationIcon,
      id: 'playVideo',
      allowedLayerTypes: ['video']
    }
  ];

  const EFFECTS_TYPES_DATA = [
    {
      label: 'Fade Top',
      icon: FadeTopIcon,
      id: 'fadeTop',
      settings: { opacity: 0, marginTop: -40 },
      direction: 'from',
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Fade Bottom',
      icon: FadeBottomIcon,
      id: 'fadeBottom',
      settings: { opacity: 0, marginTop: 40 },
      direction: 'from',
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Pulse',
      icon: PulseIcon,
      id: 'pulse',
      settings: { scale: 110 },
      direction: 'to',
      ease: { type: 'custom', preset: 'pulse' },
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    },
    {
      label: 'Visibility',
      icon: EyeIcon,
      id: 'makeVisible',
      settings: { visibility: 'hidden' },
      direction: 'to',
      ease: 'none',
      allowedLayerTypes: ['rectangle', 'circle', 'text', 'group', 'image', 'video']
    }
  ];
  const animationDataTypes =
    animationTypes === 'animation' ? ANIMATION_TYPES_DATA : EFFECTS_TYPES_DATA;
  return (
    <div>
      <>
        {!showList && isIncluded ? (
          <>
            <StyledSectionWrap>
              {editorType !== 'banner' && (
                <button
                  type='button'
                  className='add-button'
                  style={{ margin: 0, backgroundColor: '#4792E2' }}
                  id='add-animation'
                  onClick={() => {
                    onSetShowList(true);
                    capturePosthogData({
                      event: `show-add-${animationTypes}-list`,
                      type: `${animationTypes}`,
                      menu: 'right',
                      menu_type: `${animationTypes}`
                    });
                  }}>
                  <p>{`Add ${animationTypes}`}</p>
                </button>
              )}
            </StyledSectionWrap>
            {flatData.map(
              (layer) =>
                activeLayer === layer.uuid &&
                layer.animations?.map(
                  (animation, i) =>
                    animationDataTypes.some((an) => an.id === animation.type) && (
                      <div
                        key={animation.uuid}
                        ref={animation.uuid === activeAnimation.uuid ? activeAnimationRef : null}>
                        <ToggleRevealSectionButton
                          id={`right-submenu-edit-open-close-${animation.type}-${animation.uuid}`}
                          handleOnClick={() => {
                            capturePosthogData({
                              event: 'toggle-open-close-menu',
                              type: 'animation',
                              menu: 'right',
                              menu_type: 'animations',
                              animation_type: animation.type,
                              animation_id: animation.uuid,
                              is_open: !openedAnimations.includes(animation.uuid)
                            });
                            if (openedAnimations.includes(animation.uuid)) {
                              onSetOpenedAnimations(
                                openedAnimations.filter((value) => value !== animation.uuid)
                              );
                              dispatch(resetActiveAnimation());
                            } else {
                              onSetOpenedAnimations([...openedAnimations, animation.uuid]);
                              dispatch(
                                setActiveAnimation({
                                  uuid: animation.uuid,
                                  target: animation.target,
                                  index: i
                                })
                              );
                            }
                          }}
                          isOpened={openedAnimations.includes(animation.uuid)}
                          iconFill='white'
                          deleteIcon={
                            <div
                              style={{ marginRight: '10px', display: 'inline' }}
                              onClick={(e) => {
                                e.preventDefault();
                                onSetOpenedAnimations(
                                  openedAnimations.filter((value) => value !== animation.uuid)
                                );
                                dispatch(
                                  deleteAnimation(
                                    selectedFormatId,
                                    animation.target,
                                    animation.uuid
                                  )
                                );
                              }}>
                              <TrashcanIcon stroke='white' />
                            </div>
                          }
                          backgroundColor={
                            animation.uuid === activeAnimation.uuid ? '#305690' : '#2b2b2b'
                          }>
                          <div>
                            {animationDataTypes.map(
                              (anm) =>
                                anm.id === animation.type && (
                                  <anm.icon key={anm.id} className='inline-animation-icon' />
                                )
                            )}
                            <span
                              style={{
                                textTransform: 'capitalize',
                                verticalAlign: 'super'
                              }}
                              className='color-white'>
                              {animation.time}:{' '}
                              {animationDataTypes.map(
                                (anm) => anm.id === animation.type && anm.label
                              )}
                            </span>
                          </div>
                        </ToggleRevealSectionButton>

                        {openedAnimations.includes(animation.uuid) && (
                          <AnimationForm index={i} animation={animation} />
                        )}
                      </div>
                    )
                )
            )}
          </>
        ) : (
          <div style={{ paddingBottom: '13px' }}>
            {isIncluded && (
              <button
                type='button'
                style={{
                  position: 'absolute',
                  right: '18px',
                  top: '11px'
                }}
                onClick={() => onSetShowList(false)}>
                <CloseIcon />
              </button>
            )}

            <p
              style={{
                marginLeft: '17px',
                fontSize: '13px'
              }}>
              Choose animation type
            </p>

            <StyledSectionWrap
              style={{
                padding: '0px 10px',
                justifyContent: 'start'
              }}>
              {animationDataTypes.map(
                (anim) =>
                  isAnimationAllowed(
                    anim,
                    getPathById(activeLayer, presentLayers),
                    presentLayers
                  ) && (
                    <div
                      key={anim.id}
                      className='animations-small-card'
                      onClick={() => {
                        onAddAnimationEditor(
                          anim.id,
                          anim.settings && anim.settings,
                          anim.ease && anim.ease,
                          anim.direction && anim.direction
                        );
                        capturePosthogData({
                          event: `add-${animationTypes}`,
                          type: `${anim.id}`,
                          menu: 'right',
                          menu_type: `${animationTypes}`
                        });
                        onSetShowList(false);
                      }}>
                      <div className='animations-small-card-content'>
                        <anim.icon />
                      </div>
                      <span>{anim.label}</span>
                    </div>
                  )
              )}
            </StyledSectionWrap>
          </div>
        )}
      </>
    </div>
  );
}

AnimationSelection.propTypes = {
  isIncluded: PropTypes.bool.isRequired,
  showList: PropTypes.bool.isRequired,
  onSetShowList: PropTypes.func.isRequired,
  animationTypes: PropTypes.string.isRequired,
  openedAnimations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetOpenedAnimations: PropTypes.func.isRequired,
  onAddAnimationEditor: PropTypes.func.isRequired
};

export default AnimationSelection;

import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
import autosave from './reducers/autosave';

// slices
import editorSession from './slices/editorSession';
import videos from './slices/videos';
import images from './slices/images';
import template from './slices/template';
import error, { setError } from './slices/error';
import userSettings from './slices/userSettings';
import auth from './slices/auth';
import dashboard from './slices/dashboard';
import dco from './slices/dco';
import preview from './slices/preview';
import dpa from './slices/dpa';

import quickExport from './slices/quickExport';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    store.dispatch(setError(err));
    console.log(err);
  }
});

const rootReducer = combineReducers({
  images,
  template,
  autosave,
  editorSession,
  videos,
  error,
  userSettings,
  dashboard,
  dco,
  auth,
  preview,
  dpa,
  quickExport
});

// Setup state from local storage
const persistedState = {};
const zuuviAccountLocalStorageKey = 'selectedAccount';

try {
  const accountId = localStorage.getItem(zuuviAccountLocalStorageKey);

  if (accountId) {
    persistedState.auth = { currentAccountId: accountId };
  } else {
    localStorage.removeItem(zuuviAccountLocalStorageKey);
  }
} catch (err) {
  localStorage.removeItem(zuuviAccountLocalStorageKey);
}

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

// Subscribe to changes in the state
store.subscribe(() => {
  const state = store.getState();
  if (state.auth.currentAccountId) {
    // Save which account the user have selected
    localStorage.setItem(zuuviAccountLocalStorageKey, state.auth.currentAccountId);
  }
});

export default store;

export const updateZindex = (layers) => {
  const newLayers = [...layers];
  newLayers.forEach((layer, layerIndex) => {
    const zIndex = layers.length + 1 - layerIndex;
    Object.assign(layer.format, { z_index: zIndex });
    layer.type === 'group' &&
      layer.layers?.length > 0 &&
      layer.layers.forEach((childLayer, childLayerIndex) => {
        const childZIndex = layer.layers.length + 1 - childLayerIndex;
        Object.assign(childLayer.format, { z_index: childZIndex });
      });
  });
  return newLayers;
};

export const flat = (array) => {
  let result = [];
  array?.forEach((a) => {
    result.push(a);
    if (Array.isArray(a.layers)) {
      result = result.concat(flat(a.layers));
    }
  });
  return result;
};

export const recalculateSize = (originalWidth, originalHeight, border = null, padding = null) => {
  if (typeof border === 'undefined' || border === null) {
    border = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    };
  }
  if (typeof padding === 'undefined' || padding === null) {
    padding = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    };
  }
  const calculatedWidth = padding
    ? parseInt(originalWidth) -
      (parseInt(border.left) +
        parseInt(border.right) +
        parseInt(padding.left) +
        parseInt(padding.right))
    : parseInt(originalWidth) - (parseInt(border.left) + parseInt(border.right));
  const calculatedHeight = padding
    ? parseInt(originalHeight) -
      (parseInt(border.top) +
        parseInt(border.bottom) +
        parseInt(padding.top) +
        parseInt(padding.bottom))
    : parseInt(originalHeight) - (parseInt(border.top) + parseInt(border.bottom));
  return { calculatedWidth, calculatedHeight };
};
export const recalculateTextLayer = (
  activeLayerObj,
  contentEditableParentRef,
  isEditingTextEnabled,
  isNestedTextEditable,
  isTextEditable
) => {
  if (
    activeLayerObj &&
    activeLayerObj.type === 'text' &&
    contentEditableParentRef &&
    (isEditingTextEnabled || isNestedTextEditable || isTextEditable)
  ) {
    const { clientWidth, clientHeight } = contentEditableParentRef.current;
    const { border, padding, width, height, x, y } = activeLayerObj.format;

    // calculate size of the inner text editor (without border and padding)
    const { calculatedWidth, calculatedHeight } = recalculateSize(width, height, border, padding);

    // calculate size of the layer before the change happened
    const calcOriginalWidth =
      calculatedWidth +
      parseInt(border?.left) +
      parseInt(border?.right) +
      parseInt(padding?.left) +
      parseInt(padding?.right);

    const calcOriginalHeigh =
      calculatedHeight +
      parseInt(border?.top) +
      parseInt(border?.bottom) +
      parseInt(padding?.top) +
      parseInt(padding?.bottom);

    return {
      x,
      y,
      width: calcOriginalWidth,
      height: calcOriginalHeigh
    };
  }
};

export const createColorString = (colorData) => {
  if (typeof colorData === 'object' && colorData.palette && colorData.palette.length > 1) {
    const joinedStr = colorData.palette
      .map((item) => {
        const opacity =
          item.opacity && item.opacity !== 0 ? item.opacity : item.opacity === 0 ? 0 : 1;

        if (item.color.startsWith('rgba')) {
          return `${item.color.replace('/[d.]+)$/g', `, ${opacity})`)} ${
            parseFloat(item.offset).toFixed(2) * 100
          }%`;
        }
        const rgbaColor = item.color.replace('rgb(', 'rgba(').replace(')', `, ${opacity})`);
        const offset = parseFloat(parseFloat(item.offset).toFixed(3) * 100)
          .toFixed(1)
          .replace('.0', '');

        return `${rgbaColor} ${offset}%`;
      })
      .join(', ');

    if (colorData.colorType === 'linear') {
      return `${colorData.colorType}-gradient(${colorData.angle}deg, ${joinedStr})`;
    }
    if (colorData.colorType === 'radial') {
      return `${colorData.colorType}-gradient( ${joinedStr})`;
    }
  }
  if (typeof colorData === 'object' && colorData.palette && colorData.palette.length === 1) {
    const paletteColor = colorData.palette[0];

    if (paletteColor.color.startsWith('rgba')) {
      const colorString = paletteColor.color.replace('/[d.]+)$/g', `, ${paletteColor.opacity})`);
      return colorString;
    }
    if (paletteColor.color.startsWith('rgb')) {
      const colorString = paletteColor.color
        .replace('rgb(', 'rgba(')
        .replace(')', `, ${paletteColor.opacity})`);
      return colorString;
    }
    if (paletteColor.color.startsWith('#')) {
      return paletteColor.color;
    }
  }
};

export const fixFontsUrl = (fonts) => {
  const updatedFonts = fonts.map((font) => {
    // check if it's a custom font and that it doesn't contain the CDN url
    if (font.source && !font.source.includes(window._env_.REACT_ENV_CDN_URL)) {
      // check for original AWS url
      if (font.source.includes(window._env_.REACT_ENV_AWS_URL_TO_REPLACE)) {
        const modifiedSource = font.source.replace(
          window._env_.REACT_ENV_AWS_URL_TO_REPLACE,
          window._env_.REACT_ENV_CDN_URL
        );
        return {
          ...font,
          source: modifiedSource
        };
      }
      // check for new AWS url
      if (font.source.includes(window._env_.REACT_ENV_AWS_URL_2_TO_REPLACE)) {
        const modifiedSource = font.source.replace(
          window._env_.REACT_ENV_AWS_URL_2_TO_REPLACE,
          window._env_.REACT_ENV_CDN_URL
        );
        return {
          ...font,
          source: modifiedSource
        };
      }
    }
    // else return font as it was
    return font;
  });
  return updatedFonts;
};

export const updateImageState = (layerImageState, newImage) => {
  // get the original size of the new image
  const originalImgWidth = parseFloat(newImage.width);
  const originalImgHeight = parseFloat(newImage.height);
  // get the target size of the layer
  const currentImgWidth = parseFloat(layerImageState.targetSize.width);
  const currentImgHeight = parseFloat(layerImageState.targetSize.height);

  // get scales
  const widthScale = originalImgWidth / currentImgWidth;
  const heightScale = originalImgHeight / currentImgHeight;

  // we need to use the smaller scale to adjust the image to the proper ratio
  if (widthScale > heightScale) {
    layerImageState.crop.width = layerImageState.targetSize.width * heightScale;
    layerImageState.crop.height = layerImageState.targetSize.height * heightScale;
  }
  if (widthScale < heightScale) {
    layerImageState.crop.width = layerImageState.targetSize.width * widthScale;

    layerImageState.crop.height = layerImageState.targetSize.height * widthScale;
  }

  // make sure image is not cropped and it's left top aligned
  return {
    ...layerImageState,
    crop: {
      ...layerImageState.crop,
      x: 0,
      y: 0
    }
  };
};

export const findLayer = (layerId, layers) => {
  for (const layer of layers) {
    if (layer.uuid === layerId) {
      return layer;
    }
    if ('layers' in layer) {
      const child = findLayer(layerId, layer.layers);
      if (child) return child;
    }
  }
  return null;
};

export const mergePresentLayers = (tmpl, fmt, images, videos) => {
  const template = JSON.parse(JSON.stringify(tmpl));
  const format = JSON.parse(JSON.stringify(fmt));

  let bannerLayers = [];

  if ('object_data' in template) {
    if (format) {
      if (!('object_data' in format)) format.object_data = {};
      if (!('layers' in format.object_data)) format.object_data.layers = {};
      if (!('images' in format.object_data)) format.object_data.images = [];

      const combineFormat = function (formatLayer, templateLayer = {}) {
        try {
          for (const attr in formatLayer) {
            if (attr in templateLayer) {
              if (typeof formatLayer[attr] === 'object' && attr !== 'palette') {
                formatLayer[attr] = combineFormat(formatLayer[attr], templateLayer[attr]);
              } else {
                formatLayer[attr] = templateLayer[attr];
              }
            }
          }
        } catch (e) {
          return formatLayer;
        }
        return formatLayer;
      };

      function combineLayers(formatLayers, templateLayers) {
        const layers = [];
        for (const templateLayer of templateLayers) {
          const formatLayer = {
            format: 'format' in templateLayer ? { ...templateLayer.format } : {},
            settings: 'settings' in templateLayer ? { ...templateLayer.settings } : {},
            editor_settings: {},
            animations: 'animations' in templateLayer ? [...templateLayer.animations] : [],
            type: templateLayer.type,
            uuid: templateLayer.uuid
          };

          if (templateLayer.uuid in formatLayers) {
            formatLayer.format = combineFormat(
              formatLayer.format,
              formatLayers[templateLayer.uuid].format
            );

            formatLayer.settings = Object.assign(
              formatLayer.settings,
              formatLayers[templateLayer.uuid].settings
            );
            formatLayer.editor_settings = Object.assign(
              formatLayer.editor_settings,
              formatLayers[templateLayer.uuid].editor_settings
            );

            if ('animations' in formatLayers[templateLayer.uuid]) {
              for (let animation of formatLayer.animations) {
                animation = Object.assign(
                  animation,
                  formatLayers[templateLayer.uuid].animations[animation.uuid]
                );
              }
            }
          }

          const layer = {
            id: templateLayer.uuid,
            ...templateLayer,
            ...formatLayer
          };

          if (layer.type === 'image') {
            const sourceId =
              typeof layer.settings.source === 'object'
                ? layer.settings.source.uuid
                : layer.settings.source;
            if (images[sourceId]) {
              layer.settings.source = images[sourceId];
            }
          }
          if (layer.type === 'video') {
            const sourceId =
              typeof layer.settings.source === 'object'
                ? layer.settings.source.uuid
                : layer.settings.source;
            if (videos[sourceId]) {
              layer.settings.source = videos[sourceId];
            }
          }

          if (templateLayer.layers) {
            layer.children = combineLayers(formatLayers, templateLayer.layers);
            layer.layers = combineLayers(formatLayers, templateLayer.layers);
          }
          layers.push(layer);
        }
        return layers;
      }
      bannerLayers = combineLayers(format.object_data.layers, template.object_data.layers);
    }
  }
  return updateZindex(bannerLayers);
};

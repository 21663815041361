/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { createEditor, Editor, Range, Transforms } from 'slate';

import { Slate, Editable, withReact } from 'slate-react';

import { jsx } from 'slate-hyperscript';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import HoveringToolbar from './HoveringToolbar';
import replaceDcoTagInText from '../../../utils/replaceDcoTagInText';
import { updateLayerSettings } from '../../redux/slices/template';

import { rerenderSlate, rerenderSlateEditor } from '../../redux/slices/editorSession';
import { UseKeyboardShortcut } from '../../../HotkeyHandler';

export default function TextEditor(props) {
  const {
    style,
    readOnly,
    toolbarEnabled,
    textEditorRef,
    layerObject,
    toolbarRef,
    outerScale,
    doubleClickedText,
    bannerOverflowHidden,
    isEditorMenu = false
  } = props;
  const dispatch = useDispatch();
  const dcoSelection = useSelector((state) => state.dco.dcoSelection);
  const { selectedFormatId } = useSelector((state) => state.editorSession);
  const { slateId, editorId } = useSelector((state) => state.editorSession.slate);

  const editor = useMemo(() => withReact(createEditor()), []);

  const Leaf = ({ attributes, children, leaf, isEditing }) => {
    if (leaf.dynamic_key && leaf.dynamic_uuid && isEditing) {
      children = (
        <span
          id={`${leaf.dynamic_uuid}-${leaf.dynamic_key}`}
          data-cy-dynamic-key={leaf.dynamic_key}
          style={{
            display: 'inherit',
            outline: `2px solid ${leaf.dynamic_color}`,
            borderRadius: '3px'
          }}>
          {children}
        </span>
      );
    }

    if (leaf.bold) {
      children = <strong style={{ fontWeight: 'bold' }}>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    if (leaf.strikethrough) {
      children = <span style={{ textDecoration: 'line-through' }}>{children}</span>;
    }
    if (leaf.color) {
      children = <span style={{ color: leaf.color }}>{children}</span>;
    }
    if (leaf.bgColor) {
      children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>;
    }

    return <span {...attributes}>{children}</span>;
  };

  function resetCursorToStart() {
    Transforms.select(editor, {
      anchor: Editor.start(editor, []),
      focus: Editor.start(editor, [])
    });
  }

  const deserialize = (el) => {
    if (el.nodeType === 3) {
      return el.textContent;
    }
    if (el.nodeType !== 1) {
      return null;
    }
    if (el.nodeName === 'BR') {
      return '\n';
    }
    const parent = el;

    let children = Array.from(parent.childNodes).map(deserialize).flat();

    if (children.length === 0) {
      children = [{ text: '' }];
    }

    switch (el.nodeName) {
      case 'BODY':
        return jsx('fragment', {}, children);
      case 'DIV':
        return jsx('element', { type: 'div' }, children);
      case 'P':
        return jsx('element', { type: 'paragraph' }, children);
      case 'SPAN':
        return jsx('element', { type: 'span' }, children);
      default:
        return jsx('element', { type: 'paragraph' }, children);
    }
  };

  // TODO: should be moved to a util file to better get tested
  function deserializeValue(textValue) {
    if (typeof textValue === 'string') {
      textValue = `<p>${textValue}</p>`;
      textValue = textValue.replaceAll('<div><br></div>', '\n');
      textValue = textValue.replace(/<\/?span[^>]*>/g, '');
      textValue = textValue.replace(/<\/div[^>]*><\/div[^>]*>/g, '');
      textValue = textValue.replace(/<div[^>]*><div[^>]*>/g, '\n');
      textValue = textValue.replace(/<\/div[^>]*>/g, '');
      textValue = textValue.replace(/<div[^>]*>/g, '\n');
      // swap for just <br/>
      // const firstTextPart = textValue.split('<')[0];
      // textValue = textValue.replace(firstTextPart, `<div>${firstTextPart}</div>`);
      textValue = textValue.replaceAll('<div>', '\n');
      textValue = textValue.replaceAll('<div>', '\n');
      textValue = textValue.replaceAll('</div>', '');
      textValue = textValue.replace(/\u2028/g, '\n');

      // remove all occurences of the weird style from text (the whole span thingy has to go away, it's created by us somewhere)
      textValue = textValue.replace(
        /<span style="font-family: -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif;">/g,
        ''
      );
      textValue = textValue.replace(/<\/span>/g, '');

      const document = new DOMParser().parseFromString(textValue, 'text/html');
      textValue = deserialize(document.body);
    }

    return textValue;
  }

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback(
    (props) => {
      // Replace text when previewing DCO
      if (!isEditorMenu) {
        if (
          dcoSelection.dynamicSourcePreview ||
          dcoSelection.parameterSourcePreview ||
          dcoSelection.customSourcePreview
        ) {
          if (props.leaf.dynamic_key && props.leaf.dynamic_uuid) {
            for (const [key, value] of Object.entries(
              replaceDcoTagInText(props.leaf, dcoSelection)
            )) {
              props.leaf[key] = value;
            }
          }
        }
      }

      return <Leaf {...props} isEditing={!readOnly && doubleClickedText} />;
    },
    [doubleClickedText, readOnly, dcoSelection, editorId]
  );

  // Define a rendering function based on the element passed to `props`. We use
  // `useCallback` here to memoize the function for subsequent renders.
  const renderElement = useCallback(
    (props) => {
      const { element, attributes, children } = props;
      switch (element.type) {
        default:
          return <div {...attributes}>{children}</div>;
      }
    },
    [doubleClickedText, readOnly, dcoSelection, editorId]
  );

  const handleOnChange = (data) => {
    dispatch(
      updateLayerSettings(selectedFormatId, layerObject.uuid, {
        text: data
      })
    );
    if (isEditorMenu) {
      dispatch(rerenderSlate());
    } else {
      dispatch(rerenderSlateEditor());
    }
  };

  // Undo/Redo
  UseKeyboardShortcut(
    ['Z'],
    () => {
      resetCursorToStart();
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      overrideContentEditable: true
    }
  );

  UseKeyboardShortcut(
    ['Z'],
    () => {
      resetCursorToStart();
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      shiftKeyPressed: true,
      overrideContentEditable: true
    }
  );

  UseKeyboardShortcut(
    ['Y'],
    () => {
      resetCursorToStart();
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      overrideContentEditable: true
    }
  );

  const shouldScrollProp = isEditorMenu ? {} : { scrollSelectionIntoView: () => {} };

  return (
    <Slate
      className='no-scrollbars'
      key={isEditorMenu ? editorId : slateId}
      editor={editor}
      value={deserializeValue(layerObject.settings.text)}
      onChange={(value) => {
        if (!_.isEqual(layerObject.settings.text, value)) {
          handleOnChange(value);
        }
      }}>
      {!readOnly && toolbarEnabled && (
        <HoveringToolbar
          bannerOverflowHidden={bannerOverflowHidden}
          outerScale={outerScale}
          layerObject={layerObject}
          toolbarRef={toolbarRef}
          value={layerObject.settings.text}
          isEditorMenu={isEditorMenu}
        />
      )}
      <div ref={textEditorRef} style={{ height: '100%' }}>
        <Editable
          className='no-scrollbars'
          autoFocus={!isEditorMenu ? !readOnly && doubleClickedText : false} // Before it was (autoFocus={!readOnly && doubleClickedText}) removed due to autofocus shifting
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          style={style}
          {...shouldScrollProp}
        />
      </div>
    </Slate>
  );
}

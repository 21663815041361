/* eslint-disable import/prefer-default-export */
import { post, getWithoutHeaders, get } from './basicCalls';

async function getAdHtml(publishedTemplateId, publishedFormatId, CreativeId) {
  return getWithoutHeaders(
    `${window._env_.REACT_ENV_LAMBDA_URL}qa/get_template_html/${publishedTemplateId}/${publishedFormatId}/${CreativeId}`
  );
}

async function getMappings(CreativeId) {
  // We want to fetch any previous mappings. Either created in studio (DCO) or manager (DPA)
  try {
    const req = await get(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}mapping/${CreativeId}`);

    if (req.status === 200) {
      const newSettings = await req.data;
      return newSettings;
    }
  } catch (e) {
    console.log(e);
  }
}

async function getValidatedDpaColumnData(templateId, publishedTemplateId, feedUrl) {
  function getRequestData() {
    if (templateId && publishedTemplateId) {
      return { template_id: templateId, published_template_id: publishedTemplateId };
    }
    return { url: feedUrl };
  }

  const req = await post(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}validate`, getRequestData());
  return req.data;
}

async function createDpaMappings(CreativeId, seekTime, dcoId, mappings, removeInvalidRowsChecked) {
  try {
    const req = await post(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}${CreativeId}`, {
      seek_time: seekTime,
      dco_id: dcoId,
      mappings,
      remove_invalid_rows: removeInvalidRowsChecked
    });

    if (req.status === 200) {
      return await req.data;
    }
  } catch (e) {
    console.log(e);
  }
}

async function createDcoMappings(dcoId, mappings) {
  try {
    const req = await post(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}mapping/${dcoId}`, {
      mappings
    });

    if (req.status === 200) {
      return await req.data;
    }
  } catch (e) {
    console.log(e);
  }
}

async function generateDpaFeed(CreativeId) {
  try {
    const req = await get(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}generate/${CreativeId}`);

    if (req.status === 200) {
      return await req.data;
    }
  } catch (e) {
    console.log(e);
  }
}

async function triggerDpaUpdate(requestObject) {
  try {
    return await post(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}update`, requestObject);
  } catch (e) {
    console.log(e);
  }
}
async function getImagesReadyCount(CreativeId) {
  try {
    const req = await get(`${window._env_.REACT_ENV_GET_DPA_DATA_URL}get_images_status/${CreativeId}`);

    if (req.status === 200) {
      return await req.data;
    }
  } catch (e) {
    console.log(e);
  }
  return null;
}

export {
  getAdHtml,
  getValidatedDpaColumnData,
  createDpaMappings,
  createDcoMappings,
  generateDpaFeed,
  getMappings,
  triggerDpaUpdate,
  getImagesReadyCount
};

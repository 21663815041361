import React, { useState } from 'react';
import PublishTemplateButton from './PublishTemplateButton';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import PreviewTemplateButton from './PreviewTemplateButton';
import QuickExportButton from './QuickExportButton';
import useAuthorization from '../../../utils/hooks/useAuthorization';

function RightMenuFinalise() {
  const isAuthorized = useAuthorization();
  const [formSection, setFormSection] = useState({
    finaliseOpened: true
  });
  const capturePosthogData = usePosthogCapture();
  return (
    <>
      <ToggleRevealSectionButton
        id='right-submenu-edit-open-close-template-finalise-section'
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'template',
            menu: 'right',
            menu_type: 'template',
            submenu: 'finalise',
            is_open: !formSection.finaliseOpened
          });
          setFormSection({
            ...formSection,
            finaliseOpened: !formSection.finaliseOpened
          });
        }}
        isOpened={formSection.finaliseOpened}>
        <p className='color-white'>Finalise</p>
      </ToggleRevealSectionButton>
      {formSection.finaliseOpened && (
        <div className='template-settings-section'>
          <PublishTemplateButton />
          {isAuthorized({ featureFlag: 'quick-export-access' }) && <QuickExportButton />}
          <PreviewTemplateButton />
        </div>
      )}
    </>
  );
}

export default RightMenuFinalise;

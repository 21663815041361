/* eslint-disable import/prefer-default-export */
import { get, post, deleteCall } from './basicCalls';

const VIDEO_UPLOAD_URL = window._env_.REACT_ENV_VIDEO_UPLOAD_URL;

async function getVideos(templateId, campaignId, clientId = null) {
  return get(`${VIDEO_UPLOAD_URL}/${templateId}/videos`, campaignId, clientId);
}

async function uploadVideos(data, campaignId, clientId = null) {
  return post(`${VIDEO_UPLOAD_URL}/videos`, data, campaignId, clientId);
}

async function deleteVideo(imageId, campaignId = null, clientId = null) {
  return deleteCall(`${VIDEO_UPLOAD_URL}/videos/${imageId}`, campaignId, clientId);
}

async function duplicateVideo(oldTemplateId = null, newTemplateId = null, clientId = null) {
  return post(`${VIDEO_UPLOAD_URL}/videos/${oldTemplateId}/${newTemplateId}/duplicate`, clientId);
}

export { getVideos, uploadVideos, deleteVideo, duplicateVideo };

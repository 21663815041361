import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledTextarea,
  StyledFlexSpaceBetween,
  StyledCheckbox
} from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import BaseButton from '../common/BaseButton';
import ContentSpecificClicktagForm from './ContentSpecificClicktagForm';
import {
  addContentSpecificClicktag,
  setTemplateLinkedClicktags,
  setTemplateClicktag
} from '../../redux/slices/template';
import { zuuviColors } from '../../../utils/constants/colors';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  updateClicktagPreviewData,
  resetClicktagPreviewData
} from '../../redux/slices/editorSession';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import useAuthorization from '../../../utils/hooks/useAuthorization';

function RightMenuClicktag({ onStopAnimations }) {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const capturePosthogData = usePosthogCapture();

  const dcoSelection = useSelector((state) => state.dco.dcoSelection);
  const { selectedFormatId, selectedFormat, clicktagPreviewData, editorType } = useSelector(
    (state) => state.editorSession
  );

  const templateClicktags = useSelector((state) => state.template.object_data.clicktags);
  const formatClicktags = selectedFormat?.object_data?.clicktags;
  const defaultStopTime = useSelector((state) => state.template.object_data.settings.duration);

  const linkedClicktags =
    useSelector((state) => state.template.object_data.settings.linked_clicktags) ?? true;
  const templateClicktag =
    useSelector((state) => state.template.object_data.settings.clicktag) ?? '';

  const template = useSelector((state) => state.template);
  const format = template.formats.find((format) => format.id === selectedFormatId)

  const formatClicktag =
    (selectedFormat && format.object_data?.settings?.clicktag) ?? false;

  const globalClicktag = (linkedClicktags ? templateClicktag : formatClicktag) ?? '';
  const clicktags = (linkedClicktags ? templateClicktags : formatClicktags) ?? [];

  const [formSection, setFormSection] = useState({
    globalClicktagSettingsOpened: true,
    contentSpecifiClicktagSettingsOpened: true
  });

  // default clicktag object that will be saved
  function createContentSpecificClicktagObject() {
    const clicktagId = uuidv4();
    const newClicktagObj = {
      uuid: clicktagId,
      title: clicktags ? `Clicktag ${clicktags.length + 1}` : 'Clicktag 1',
      url: '',
      start_time: '0.0',
      // default stop_time is the duration of the template
      stop_time: parseFloat(defaultStopTime).toFixed(1)
    };
    dispatch(addContentSpecificClicktag(newClicktagObj, selectedFormatId));
  }

  const dynamicSourceKeys =
    dcoSelection.dynamicSource?.addon === 'google-studio'
      ? dcoSelection.dynamicSource?.keys?.keys
      : dcoSelection.dynamicSource?.keys;

  //
  return (
    <>
      {editorType === 'banner' && (
        <StyledSectionWrap>
          <StyledFlexSpaceBetween height='100%' boxWidth='100%' alignTop>
            <StyledCheckbox
              id='right-submenu-clicktag-linked-clicktags'
              type='checkbox'
              checked={linkedClicktags}
              onChange={(e) => {
                dispatch(setTemplateLinkedClicktags(e.target.checked, selectedFormatId));
              }}
            />
            <p className=' color-white'>Use same clicktags across all creatives</p>
          </StyledFlexSpaceBetween>
        </StyledSectionWrap>
      )}
      <ToggleRevealSectionButton
        id='right-submenu-edit-open-close-global-clicktag-section'
        handleOnClick={() => {
          capturePosthogData({
            event: `toggle-open-close-${editorType}-global-clicktag-section`,
            type: 'clicktag',
            menu: 'right',
            menu_type: 'template',
            submenu: 'template-clicktag',
            is_open: !formSection.globalClicktagSettingsOpened
          });
          setFormSection({
            ...formSection,
            globalClicktagSettingsOpened: !formSection.globalClicktagSettingsOpened
          });
        }}
        isOpened={formSection.globalClicktagSettingsOpened}>
        <p className='color-white'>Global Clicktag</p>
      </ToggleRevealSectionButton>
      {formSection.globalClicktagSettingsOpened && (
        <StyledSectionWrap>
          <StyledTextarea
            value={globalClicktag}
            onChange={(event) => {
              dispatch(setTemplateClicktag(event.target.value, selectedFormatId));
            }}
          />

          {(dcoSelection.dynamicSourceIncluded ||
            dcoSelection.parameterSourceIncluded ||
            dcoSelection.customSourceIncluded) && (
            <>
              <p style={{ margin: '15px 0px 5px 0px', fontSize: '13px', width: '100%' }}>
                {`Available ${
                  dcoSelection.dynamicSource?.export === 'dpa' ? 'Catalog Ads' : 'DCO'
                } values`}
              </p>

              {dcoSelection.dynamicSourceIncluded && (
                <div style={{ width: '100%', marginBottom: '4px' }}>
                  <p
                    style={{
                      width: '100%',
                      color: 'rgba(255,255,255,0.8',
                      margin: '2px 0px',
                      fontSize: '11px'
                    }}>
                    {dcoSelection.dynamicSource.name}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      marginTop: 6
                    }}>
                    {dynamicSourceKeys.map((parameter, i) => (
                      <button
                        type='button'
                        key={i}
                        className='dco-clicktag-key-button-dynamic'
                        title={parameter}
                        onClick={() => {
                          dispatch(setTemplateClicktag(
                            `${globalClicktag}$\{${parameter}}`,
                            selectedFormatId
                          ));
                        }}>
                        {parameter}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {dcoSelection.parameterSourceIncluded && (
                <div style={{ width: '100%' }}>
                  <p
                    style={{
                      width: '100%',
                      color: 'rgba(255,255,255,0.8',
                      margin: '2px 0px',
                      fontSize: '11px'
                    }}>
                    {dcoSelection.parameterSource.name}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      marginTop: 6
                    }}>
                    {dcoSelection.parameterSource.keys.map((parameter, i) => (
                      <button
                        type='button'
                        key={i}
                        className='dco-clicktag-key-button-parameter'
                        title={parameter.parameter}
                        onClick={() => {
                         dispatch(setTemplateClicktag(
                            `${globalClicktag}$\{${parameter.parameter}}`,
                            selectedFormatId
                          ));
                        }}>
                        {parameter.parameter}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {dcoSelection.customSourceIncluded && (
                <div style={{ width: '100%' }}>
                  <p
                    style={{
                      width: '100%',
                      color: 'rgba(255,255,255,0.8',
                      margin: '2px 0px',
                      fontSize: '11px'
                    }}>
                    {dcoSelection.customSource.name}
                  </p>
                  {dcoSelection.customSource.json.keys.map((custom, i) => (
                    <button
                      type='button'
                      key={i}
                      className='dco-clicktag-key-button-custom'
                      title={custom.parameter}
                      onClick={() => {
                        dispatch(setTemplateClicktag(
                          `${globalClicktag}$\{${custom.parameter}}`,
                          selectedFormatId
                        ));
                      }}>
                      {custom.parameter}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </StyledSectionWrap>
      )}
      {isAuthorized({ featureFlag: 'multiple-clicktag' }) && (
        <>
          <ToggleRevealSectionButton
            id='right-submenu-edit-open-close-global-clicktag-section'
            handleOnClick={() => {
              capturePosthogData({
                event: `toggle-open-close-${editorType}-global-clicktag-section`,
                type: 'clicktag',
                menu: 'right',
                menu_type: 'template',
                submenu: 'template-clicktag',
                is_open: !formSection.contentSpecifiClicktagSettingsOpened
              });
              setFormSection({
                ...formSection,
                contentSpecifiClicktagSettingsOpened:
                  !formSection.contentSpecifiClicktagSettingsOpened
              });
            }}
            isOpened={formSection.contentSpecifiClicktagSettingsOpened}>
            <p className='color-white'>Content Specific Clicktags</p>
          </ToggleRevealSectionButton>
          {formSection.contentSpecifiClicktagSettingsOpened && (
            <>
              <StyledSectionWrap>
                <BaseButton
                  id='add-content-specific-clicktag-button'
                  text='Add content specific clicktag'
                  style={{ backgroundColor: '#4792E2', color: 'white' }}
                  onClick={() => createContentSpecificClicktagObject()}
                />
                {clicktags && clicktags.length > 0 && (
                  <BaseButton
                    id='preview-clicktags'
                    secondary
                    text={
                      clicktagPreviewData.length === clicktags.length
                        ? 'Hide clicktags'
                        : 'Show all clicktag overlays'
                    }
                    style={{
                      color: 'white',
                      marginTop: 7,
                      ...(clicktagPreviewData.length === clicktags.length && {
                        backgroundColor: '#4792E2'
                      })
                    }}
                    onClick={() => {
                      // toggle is clicktag is previewed or not
                      if (clicktagPreviewData.length !== clicktags.length) {
                        // if it's previewed create object used to render in the animations
                        clicktags.forEach((clicktag) => {
                          dispatch(
                            updateClicktagPreviewData({
                              clicktag,
                              // zuuviColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`
                              zuuviColor:
                                zuuviColors[Math.floor(Math.random() * zuuviColors.length)].color
                            })
                          );
                        });
                      } else {
                        dispatch(resetClicktagPreviewData());
                      }
                    }}
                  />
                )}{' '}
              </StyledSectionWrap>
              {clicktags &&
                clicktags.length > 0 &&
                clicktags.map((clicktag) => (
                  <ContentSpecificClicktagForm
                    clicktag={clicktag}
                    key={clicktag.uuid}
                    onStopAnimations={onStopAnimations}
                    onCapturePosthogData={(data) =>
                      capturePosthogData({
                        ...data
                      })
                    }
                  />
                ))}
            </>
          )}
        </>
      )}
    </>
  );
}

export default RightMenuClicktag;

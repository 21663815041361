import { createSlice } from '@reduxjs/toolkit';

const initialThreshold = 1000;

const initialState = {
  title: '',
  items: [],
  itemsExpanded: [],
  adResizeThreshold: initialThreshold,
  gridMaxSize: initialThreshold,
  windowDimensions: { width: 0, height: 0 }
};

const preview = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    getTemplate() {},
    getCampaign() {},
    setTitle(state, action) {
      state.title = action.payload;
    },
    setItemExpanded(state, action) {
      const { key, expand } = action.payload;

      if (expand && !state.itemsExpanded.includes(key)) {
        state.itemsExpanded.push(key);
      } else if (!expand && state.itemsExpanded.includes(key)) {
        state.itemsExpanded.splice(state.itemsExpanded.indexOf(key), 1);
      }
    },
    setItems(state, action) {
      const items = action.payload;
      // TODO: This logic should be moved to a hook or saga.

      // Sort items
      function compareFunction(a, b) {
        if (a.width > b.width) {
          return -1;
        }

        if (a.width < b.width) {
          return 1;
        }

        return 0;
      }

      items.sort(compareFunction);

      const rowItems = [];

      // Recalculate values
      items.forEach((item, index) => {
        // Find item scale

        if (item.width > item.height) {
          if (item.width + 50 > state.adResizeThreshold) {
            const s = (item.width - state.adResizeThreshold) / item.width;
            item.scale = s > 0 ? 1 - s : 1;
          }
        } else if (item.height + 50 > state.adResizeThreshold) {
          const s = (item.height - state.adResizeThreshold) / item.height;
          item.scale = s > 0 ? 1 - s : 1;
        }

        // Find position
        let sum = 0;
        const spacing = 30;

        const itemSize = (i) => Math.round(i.width * i.scale + spacing);

        let row = 0;

        if (rowItems.length === 0) {
          // If we dont have any rows at all yet.
          sum = 0;
          rowItems.push([item]);
        } else {
          // If we do have atleast one row

          for (let i = 0; i < rowItems.length; i++) {
            // For each row that we have
            const r = rowItems[i];
            row = i;

            if (r.length === 0) {
              // If the row doesnt have any items
              sum = 0;
              r.push(item);
              break;
            } else {
              // check if we fit in this row.

              // Find the size of elements already in the row
              const rowWidth = r
                .map((i) => i.width + spacing)
                .reduce((prev, curr) => prev + curr, 0);

              // Check if we fit with those items
              if (rowWidth + itemSize(item) < state.gridMaxSize) {
                // We do fit in there
                sum = rowWidth;
                r.push(item);
                break;
              } else {
                // We dont fit in there

                rowItems.push([]);
              }
            }
          }
        }

        item.y = row;
        item.x = index === 0 ? 0 : sum;
      });

      state.items = items;
    },
    setWindowDimensions(state, action) {
      const { width, height } = action.payload;
      state.windowDimensions.width = width;
      state.windowDimensions.height = height;
      state.gridMaxSize = width < initialThreshold ? width - 50 : initialThreshold - 50;
      state.adResizeThreshold = width < initialThreshold ? width - 50 : initialThreshold - 50;

      const rowItems = [];

      // Recalculate values
      state.items.forEach((item, index) => {
        // Find item scale

        if (item.width > item.height) {
          if (item.width + 50 > state.adResizeThreshold) {
            const s = (item.width - state.adResizeThreshold) / item.width;
            item.scale = s > 0 ? 1 - s : 1;
          }
        } else if (item.height + 50 > state.adResizeThreshold) {
          const s = (item.height - state.adResizeThreshold) / item.height;
          item.scale = s > 0 ? 1 - s : 1;
        }

        // Find position
        let sum = 0;
        const spacing = 30;

        const itemSize = (i) => Math.round(i.width * i.scale + spacing);

        let row = 0;

        if (rowItems.length === 0) {
          // If we dont have any rows at all yet.
          sum = 0;
          rowItems.push([item]);
        } else {
          // If we do have atleast one row

          for (let i = 0; i < rowItems.length; i++) {
            // For each row that we have
            const r = rowItems[i];
            row = i;

            if (r.length === 0) {
              // If the row doesnt have any items
              sum = 0;
              r.push(item);
              break;
            } else {
              // check if we fit in this row.

              // Find the size of elements already in the row
              const rowWidth = r
                .map((i) => i.width + spacing)
                .reduce((prev, curr) => prev + curr, 0);

              // Check if we fit with those items
              if (rowWidth + itemSize(item) < state.gridMaxSize) {
                // We do fit in there
                sum = rowWidth;
                r.push(item);
                break;
              } else {
                // We dont fit in there

                rowItems.push([]);
              }
            }
          }
        }

        item.y = row;
        item.x = index === 0 ? 0 : sum;
      });
    }
  }
});

export const {
  setItems,
  setWindowDimensions,
  getTemplate,
  getCampaign,
  setTemplate,
  setItemExpanded,
  setTitle
} = preview.actions;
export default preview.reducer;

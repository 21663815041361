/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable import/no-cycle */
/* eslint-disable import/first */
/* eslint-disable no-shadow */
import { call, select, put, fork, takeLatest, takeEvery, take } from 'redux-saga/effects';

import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { eventChannel } from 'redux-saga';
import {
  getTemplate,
  setTemplate,
  resetUndoHistory,
  updateLayerImageState,
  updateLayerPosition,
  undo,
  redo,
  saveAd,
  updateLayerSettings,
  updateFormatLayerSettings,
  updateLayerFormat,
  reorderLayers,
  updateLayerName,
  updateLayerEditorSettings,
  updateLayerFont,
  updateLayerImage,
  updateLayerImageScale,
  addLayer,
  addGroupLayer,
  pasteLayer,
  deleteLayer,
  updateLayerVideo,
  updateLayerDCO,
  setTemplateDCO,
  addAnimation,
  deleteAnimation,
  updateAnimation,
  // setTemplateClicktag
} from '../slices/template';
import { loadUsedAssets, loadAssets } from './assetsHelper';
import { setError } from '../slices/error';
import * as api from '../../api';

import { setCurrentAccountId } from '../slices/auth';
import { createColorString, fixFontsUrl, flat, mergePresentLayers } from '../../../helpers';
import {
  buildAnimations,
  pauseAnimations,
  playAnimations,
  resetActiveAnimation,
  selectFormat,
  setAnimation,
  setDirtySaveState,
  setPresentLayers,
  setSelectedFonts,
  setTimelineTime,
  stopAnimations
} from '../slices/editorSession';
import {
  fetchDcoFromTemplate,
  increaseSelectedRow,
  resetDcoSelection,
  resetSelectedRow
} from '../slices/dco';
import {
  addImage,
  duplicateFormatImageBase64Data,
  duplicateLayerImageBase64Data,
  loadImages,
  setImageBase64Data,
  setOverwriteImageBase64Data
} from '../slices/images';

export const selectTemplate = (state) => state.template;
export const selectedFormat = (state) => state.editorSession.selectedFormat;
export const selectImages = (state) => state.images;
export const selectVideos = (state) => state.videos;
export const selectAnimation = (state) => state.editorSession.studio.animation;
export const selectTimeline = (state) => state.editorSession.studio.timeline;
export const selectPresentLayers = (state) => state.editorSession.studio.presentLayers;
export const selectRepeats = (state) => state.template.object_data.settings.repeats;
export const selectMoveEndHandleX = (state) => state.template.object_data.settings.duration;
export const selectTimelineRepeatFrom = (state) =>
  'repeatFrom' in state.template.object_data.settings
    ? state.template.object_data.settings.repeatFrom
    : 0;
export const selectAnimationsDuration = (state) => state.template.object_data.settings.duration;
export const selectDcoSelection = (state) => state.dco;
export const selectClicktagPreviewData = (state) => state.editorSession.clicktagPreviewData;

export function* handleGetTemplateSucceeded(template) {
  yield put(setCurrentAccountId(template.client_id));
  yield put(setTemplate(template));

  // Select the format
  const pathName = window.location.pathname;
  const regex = /\/width\/([\d]*)\/height\/([\d]*)/;
  const matches = pathName.match(regex);

  let format = template.formats[0];

  if (matches && matches.length === 3) {
    const width = matches[1];
    const height = matches[2];
    const matchedFormat = template.formats.find(
      (f) => f.object_data.width === parseInt(width) && f.object_data.height === parseInt(height)
    );
    if (matchedFormat) format = matchedFormat;
  }

  yield put(selectFormat(format));

  yield put(resetUndoHistory());

  // Set DCO
  yield put(resetDcoSelection());
  if (
    template.object_data.dynamic &&
    (template.object_data.dynamic.dynamic_uuid ||
      template.object_data.dynamic.parameter_uuid ||
      ('custom' in template.object_data.dynamic &&
        'id' in template.object_data.dynamic.custom &&
        template.object_data.dynamic.custom.id))
  ) {
    yield put(fetchDcoFromTemplate());
  }

  // Load thumbnail images
  yield call(loadAssets);

  yield call(loadUsedAssets, template);
}

export function* handleGetTemplate(action) {
  try {
    const response = yield call(api.templateStore.getTemplate, action.payload);
    yield fork(handleGetTemplateSucceeded, response.data);
  } catch (e) {
    yield put(setError(e));
  }
}

export function* handleUpdateLayerImageState(action) {
  const { layerId, formatId, imageState, resize } = action.payload;

  const templateState = yield select(selectTemplate);

  if (resize) {
    for (const format of templateState.formats) {
      if (format.id === formatId) {
        if (!format.object_data.layers[layerId]) {
          format.object_data.layers[layerId] = {};
        }

        const layer = format.object_data.layers[layerId];

        if (!('settings' in layer)) {
          layer.settings = {};
        }

        if (!('imageState' in layer.settings)) {
          layer.settings.imageState = {};
        }

        if (imageState.crop !== undefined && resize) {
          const height =
            imageState.targetSize !== undefined
              ? imageState.targetSize.height
              : imageState.crop.height;

          const width =
            imageState.targetSize !== undefined
              ? imageState.targetSize.width
              : imageState.crop.width;
          yield put(
            updateLayerPosition(formatId, layerId, {
              height: height || 0,
              width: width || 0,
              x: layer.format.x || 0,
              y: layer.format.y || 0
            })
          );
        }
      }
    }
  }
}

export function* handleRedoUndo() {
  yield put(setDirtySaveState(true));
}

export function* handleSaveAd() {
  yield put(setDirtySaveState(false));
}

export function handleResetAnimations(timeline, animation, selectedFormat, clicktagPreviewData) {
  selectedFormat?.forEach((layer) => {
    const { opacity, border, padding, background_color, radius, width, height, x, y, rotation } =
      layer.format;
    const isIgnored = layer.settings?.ignored;
    const isHidden = layer.editor_settings?.hidden;

    const hasMoveAnimation = (animations) =>
      animations.some((animation) => ['moveXY', 'moveX', 'moveY'].includes(animation.type));
    const hasRotateAnimation = (animations) =>
      animations.some((animation) => animation.type === 'rotate');

    if (rotation !== 0) {
      const element = document.getElementById(`layer-${layer.uuid}`);
      if (hasMoveAnimation(layer.animations)) {
        // We use this to make move animation consistent if the layer itself is rotated
        const elementGrandparent = element.parentElement.parentElement;
        timeline.set(elementGrandparent, {
          top: y,
          left: x
        });
      }
      if (hasRotateAnimation(layer.animations)) {
        // We use this to make rotate animation consistent if the layer itself is rotated
        const elementParent = element.parentElement;
        timeline.set(elementParent, {
          transform: `rotate(${rotation}deg)`
        });
      }
    }

    timeline.set(`#layer-${layer.uuid}`, {
      transform: '',
      top: 0,
      left: 0,
      opacity: opacity / 100,
      display: isIgnored || isHidden ? 'none' : 'initial',
      width: `calc(100% - ${border ? parseInt(border.left) : 0}px - ${
        border ? parseInt(border.right) : 0
      }px - ${parseInt(padding ? padding.left : 0)}px - ${
        padding ? parseInt(padding.right) : 0
      }px)`,
      height: `calc(100% - ${border ? parseInt(border.top) : 0}px - ${
        border ? parseInt(border.bottom) : 0
      }px - ${padding ? parseInt(padding.top) : 0}px - ${
        padding ? parseInt(padding.bottom) : 0
      }px)`,
      marginTop: 0,
      scale: 1,
      ...(layer.type !== 'image' && {
        background:
          background_color !== undefined
            ? `${createColorString(background_color)} center no-repeat`
            : ''
      }),
      borderRadius: radius,
      visibility: 'visible'
    });
    // maybe check if the element exists
    if (
      layer.settings.clicktag &&
      layer.settings.clicktag.length > 0 &&
      clicktagPreviewData.length > 0
    ) {
      timeline.set(`#overlay-${layer.uuid}`, {
        transform: '',
        border: '1px solid blue',
        top: y,
        left: x,
        opacity: opacity / 100,
        display: 'initial',
        width: width - 1,
        height: height - 1,
        marginTop: 0,
        scale: 1,
        backgroundColor: 'transparent',
        borderRadius: radius
      });
    }
    layer.layers?.length > 0 &&
      layer.layers.forEach((childLayer) => {
        const {
          opacity,
          border,
          padding,
          background_color,
          radius,
          width,
          height,
          x,
          y,
          rotation
        } = childLayer.format;

        const isChildIgnored = childLayer.settings?.ignored;
        const isChildHidden = childLayer.editor_settings?.hidden;

        if (rotation !== 0) {
          const childElement = document.getElementById(`layer-${childLayer.uuid}`);
          if (hasMoveAnimation(childLayer.animations)) {
            // We use this to make move and rotate animation consistend if the layer itself is rotated
            const childElementGrandparent = childElement.parentElement.parentElement;
            timeline.set(childElementGrandparent, {
              top: y,
              left: x
            });
          }
          if (hasRotateAnimation(childLayer.animations)) {
            // We use this to make rotate animation consistent if the layer itself is rotated
            const childElementParent = childElement.parentElement;
            timeline.set(childElementParent, {
              transform: `rotate(${rotation}deg)`
            });
          }
        }

        timeline.set(`#layer-${childLayer.uuid}`, {
          transform: '',
          left: '',
          top: '',
          opacity: opacity / 100,
          display: isChildIgnored || isChildHidden ? 'none' : 'initial',
          width: `calc(100% - ${border ? parseInt(border.left) : 0}px - ${
            border ? parseInt(border.right) : 0
          }px - ${parseInt(padding ? padding.left : 0)}px - ${
            padding ? parseInt(padding.right) : 0
          }px)`,
          height: `calc(100% - ${border ? parseInt(border.top) : 0}px - ${
            border ? parseInt(border.bottom) : 0
          }px - ${padding ? parseInt(padding.top) : 0}px - ${
            padding ? parseInt(padding.bottom) : 0
          }px)`,
          marginTop: 0,
          scale: 1,
          ...(childLayer.type !== 'image' && {
            background:
              background_color !== undefined
                ? `${createColorString(background_color)} center no-repeat`
                : ''
          }),
          borderRadius: radius,
          visibility: 'inherit'
        });
        if (
          childLayer.settings.clicktag &&
          childLayer.settings.clicktag.length > 0 &&
          clicktagPreviewData.length > 0
          // maybe check if the element exists
        ) {
          timeline.set(`#overlay-${childLayer.uuid}`, {
            transform: '',
            border: '1px solid blue',
            top: y,
            left: x,
            opacity: opacity / 100,
            display: 'initial',
            width: width - 1,
            height: height - 1,
            marginTop: 0,
            scale: 1,
            backgroundColor: 'transparent',
            borderRadius: radius
          });
        }
      });
  });
}

const endTweens = [];
function* onRepeatfcn(timeline) {
  const timelineRepeatFrom = yield select(selectTimelineRepeatFrom);

  let tmpRepeats = timeline._repeat;
  if (--tmpRepeats === 0) {
    // Using Object.keys() to iterate over endTweens' indices
    Object.keys(endTweens).forEach((index) => {
      const tween = endTweens[index];
      if (typeof tween.pause === 'function') {
        tween.pause(0.001); // Wait small amount, to make sure it restarted before actually pausing it
      }
    });
  }
  timeline.seek(timelineRepeatFrom);
  timeline.repeat(tmpRepeats);
}

function* handleOnUpdate(timeline) {
  yield put(setTimelineTime(timeline.totalTime()));
}

function* handleOnRepeat(timeline) {
  const template = yield select(selectTemplate);
  const dcoSelection = yield select(selectDcoSelection);
  if (template.object_data.settings.rotateOnRepeat) {
    if (dcoSelection.dynamicSourceIncluded) {
      yield put(increaseSelectedRow());
    }
  }
  yield call(onRepeatfcn, timeline);
}

function* handleOnComplete() {
  const animation = yield select(selectAnimation);
  yield put(setAnimation({ ...animation, isPlaying: false, isPaused: false }));
  yield call(handleStopAnimations);
}

function createTimelineEventChannel(timeline) {
  return eventChannel((emit) => {
    const onUpdate = () => emit({ type: 'onUpdate' });
    const onRepeat = () => emit({ type: 'onRepeat' });
    const onComplete = () => emit({ type: 'onComplete' });

    // Setting up the timeline event callbacks
    timeline.eventCallback('onUpdate', onUpdate);
    timeline.eventCallback('onRepeat', onRepeat);
    timeline.eventCallback('onComplete', onComplete);

    // The subscriber must return an unsubscribe function
    return () => {
      timeline.eventCallback('onUpdate', null);
      timeline.eventCallback('onRepeat', null);
      timeline.eventCallback('onComplete', null);
    };
  });
}

function* addTimelineEventListeners(timeline) {
  const channel = yield call(createTimelineEventChannel, timeline);
  try {
    while (true) {
      const action = yield take(channel);
      switch (action.type) {
        case 'onUpdate':
          yield fork(handleOnUpdate, timeline);
          break;
        case 'onRepeat':
          yield fork(handleOnRepeat, timeline);
          break;
        case 'onComplete':
          yield fork(handleOnComplete, timeline);
          break;
        default:
          // Handle any other actions or default case
          break;
      }
    }
  } finally {
    channel.close();
  }
}

// normal pulse ease
gsap.registerPlugin(CustomEase);
const pulseCustomEase = CustomEase.create(
  'custom',
  'M0,0 C0,0 0.01739,0.07989 0.02396,0.13207 0.06038,0.42135 0.06879,0.60353 0.1097,0.87117 0.11573,0.91068 0.13445,0.94003 0.15239,0.97238 0.15744,0.98149 0.16693,0.98676 0.17655,0.99172 0.1862,0.99671 0.19748,1.00216 0.20642,1.00028 0.21883,0.99766 0.23413,0.9889 0.24314,0.97766 0.26054,0.95593 0.27774,0.93365 0.28312,0.90449 0.33689,0.61272 0.35693,0.37062 0.40865,0.07957 0.41257,0.05753 0.42567,0.04093 0.43951,0.02442 0.44892,0.0132 0.46425,0.00049 0.4751,0.00031 0.48592,0.00013 0.50181,0.01238 0.51136,0.02322 0.5241,0.03768 0.53459,0.05305 0.53929,0.07319 0.59332,0.30448 0.61701,0.46527 0.67338,0.71682 0.69147,0.79755 0.70197,0.85025 0.72913,0.92031 0.74071,0.95019 0.76155,0.97546 0.78083,0.99398 0.78726,1.00015 0.80104,1.00007 0.81049,0.99847 0.82008,0.99684 0.83142,0.99224 0.83747,0.98438 0.85453,0.9622 0.86559,0.93898 0.87914,0.90925 0.8932,0.87842 0.9055,0.8582 0.90983,0.8256 0.95022,0.52176 1,0 1,0 '
);
const changeResizeAnimationSettings = (direction, type, animValue, width, height, animation) => {
  const layerWidth = width;
  const layerHeight = height;
  if (type === 'width') {
    const animationWidth = animValue || animation.settings.width;
    if (animationWidth === layerWidth) {
      return { left: 0, transformOriginWidth: direction };
    }
    if (direction === 'left') {
      // the width of the layer increases so we need to move layer to +x value
      if (animationWidth > layerWidth) {
        const left = layerWidth - animationWidth;
        return { left, transformOriginWidth: direction };
      }
      // the width of the layer decreases so we need to move layer to -x value
      if (animationWidth < layerWidth) {
        const left = layerWidth - animationWidth;
        return { left, transformOriginWidth: direction };
      }
    }
    if (direction === 'right') {
      // no need to move the layer
      return { left: 0, transformOriginWidth: direction };
    }
    if (direction === 'center') {
      // we need to move tha layer to -x value of half of the changed width
      if (animationWidth < layerWidth) {
        const left = (layerWidth - animationWidth) / 2;
        return { left, transformOriginWidth: direction };
      }
      // we need to move tha layer to +x value of half of the changed width
      if (animationWidth > layerWidth) {
        const left = (layerWidth - animationWidth) / 2;
        return { left, transformOriginWidth: direction };
      }
    }
  }
  if (type === 'height') {
    const animationHeight = animValue || animation.settings.height;

    if (animationHeight === layerHeight) {
      return { top: 0, transformOriginHeight: direction };
    }
    if (direction === 'top') {
      // the height of the layer increases so we need to move layer to +y value
      if (animationHeight > layerHeight) {
        const top = layerHeight - animationHeight;
        return { top, transformOriginHeight: direction };
      }

      // the height of the layer decreases so we need to move layer to -y value
      if (animationHeight < layerHeight) {
        const top = layerHeight - animationHeight;
        return { top, transformOriginHeight: direction };
      }
    }
    if (direction === 'bottom') {
      // no need to move the layer
      return { top: 0, transformOriginHeight: direction };
    }
    if (direction === 'center') {
      // we need to move tha layer to -y value of half of the changed height
      if (animationHeight < layerHeight) {
        const top = (layerHeight - animationHeight) / 2;
        return { top, transformOriginHeight: direction };
      }
      // we need to move tha layer to +y value of half of the changed height
      if (animationHeight > layerHeight) {
        const top = (layerHeight - animationHeight) / 2;
        return { top, transformOriginHeight: direction };
      }
    }
  }
  return undefined;
};

export function* handleRerenderLayers() {
  const currentFormat = yield select(selectedFormat);
  const template = yield select(selectTemplate);
  const images = yield select(selectImages);
  const videos = yield select(selectVideos);

  if (currentFormat && template) {
    // We do this since changes are not made to the selectedFormat, but the formats from the template.
    const format = template?.formats?.find((f) => f.id === currentFormat.id);

    let selectedFonts = [];
    if (template.object_data.fonts && template.object_data.fonts.length > 0)
      selectedFonts = template.object_data.fonts;
    else if (format.object_data.fonts && format.object_data.fonts.length > 0)
      selectedFonts = format.object_data.fonts;

    if (selectedFonts !== undefined && selectedFonts.length > 0) {
      selectedFonts = fixFontsUrl(selectedFonts);
    }

    yield put(setSelectedFonts(selectedFonts));

    const presentLayers = mergePresentLayers(template, format, images, videos);
    yield put(setPresentLayers(presentLayers));

    yield put(resetActiveAnimation());
  }
}
export function* updateAnimationSettings(animation, resizeSettingsTop, resizeSettingsLeft) {
  const currentFormat = yield select(selectedFormat);
  yield put(
    updateAnimation(currentFormat.id, animation.target, {
      ...animation,
      settings: {
        ...animation.settings,
        top: resizeSettingsTop,
        left: resizeSettingsLeft
      }
    })
  );
}
export function* handleBuildAnimations() {
  const animation = yield select(selectAnimation);
  const timeline = yield select(selectTimeline);
  const presentLayers = yield select(selectPresentLayers);
  const repeats = yield select(selectRepeats);
  const moveEndHandleX = yield select(selectMoveEndHandleX);
  const animationsDuration = yield select(selectAnimationsDuration);
  yield put(setAnimation({ ...animation, isPlaying: true }));
  let updatedSettings = {};
  for (const layer of flat(presentLayers)) {
    // if we have amimaton making element visible we have to add tween to make it invisible first
    const makeVisibleAnimExists = layer.animations
      .filter((anim) => anim.type === 'makeVisible')
      .sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
    const resizeXYAnimations = layer.animations
      .filter((anim) => anim.type === 'resizeXY')
      .sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
    // if there is at least one and first one is not direction "to" hide
    if (
      makeVisibleAnimExists.length !== 0 &&
      makeVisibleAnimExists[0].direction === 'from' &&
      timeline
    ) {
      if (document.getElementById(`overlay-${makeVisibleAnimExists[0].target}`)) {
        timeline.set(
          document.getElementById(`overlay-${makeVisibleAnimExists[0].target}`),
          { visibility: 'hidden' },
          0
        );
      }
      timeline.set(
        document.getElementById(`layer-${makeVisibleAnimExists[0].target}`),
        { visibility: 'hidden' },
        0
      );
    }
    for (const animation of layer.animations) {
      // resizeXY animation update settings object
      if (resizeXYAnimations.length > 0 && animation.type === 'resizeXY') {
        const animIndex = resizeXYAnimations.indexOf(animation);
        const preAnim =
          animIndex > 0
            ? {
                width: resizeXYAnimations[animIndex - 1].settings.width,
                height: resizeXYAnimations[animIndex - 1].settings.height
              }
            : { width: layer.format.width, height: layer.format.height };

        const resizeSettingsTop = changeResizeAnimationSettings(
          animation.transformOriginHeight || 'bottom',
          'height',
          animation.settings.height,
          preAnim.width || layer.format.width,
          preAnim.height || layer.format.height,
          animation
        );
        const resizeSettingsLeft = changeResizeAnimationSettings(
          animation.transformOriginWidth || 'right',
          'width',
          animation.settings.width,
          preAnim.width || layer.format.width,
          preAnim.height || layer.format.height,
          animation
        );

        yield call(
          updateAnimationSettings,
          animation,
          resizeSettingsTop.top,
          resizeSettingsLeft.left
        );

        updatedSettings = {
          ...animation.settings,
          top: resizeSettingsTop.top,
          left: resizeSettingsLeft.left
        };
      }
      if (animation.type === 'playVideo') {
        const videoElement = document.getElementById(`video-layer-${animation.target}`);
        if (videoElement.readyState < 1) videoElement.load(); // Load video so it's ready to play in the animations.
        if (timeline) {
          timeline.playVideo(videoElement, parseFloat(animation.time));
          timeline.pauseVideo(
            videoElement,
            parseFloat(animation.time) + parseFloat(animation.duration)
          );
        }
      }
      if (
        animation.settings &&
        ('opacity' in animation.settings || 'scale' in animation.settings)
      ) {
        if (animation.type === 'pulse' && animation.ease.preset === 'pulse') {
          updatedSettings = {
            ...animation.settings,
            scale: animation.settings.scale / 100,
            duration: parseFloat(animation.duration),
            ease: pulseCustomEase
          };
        } else if (
          animation.type === 'opacity' ||
          animation.type === 'fadeTop' ||
          animation.type === 'fadeBottom'
        ) {
          updatedSettings = {
            ...animation.settings,
            opacity: animation.settings.opacity / 100,
            duration: parseFloat(animation.duration),
            ease: `${animation.ease}.${animation.easeType}`
          };
        } else {
          updatedSettings = {
            ...animation.settings,
            scale: animation.settings.scale / 100,
            duration: parseFloat(animation.duration),
            ease: `${animation.ease}.${animation.easeType}`
          };
        }
      } else {
        const animationSettings = { ...animation.settings };
        if (animation.type === 'moveX') {
          animationSettings.left = `+=${animationSettings.left}`;
        }
        if (animation.type === 'moveY') {
          animationSettings.top = `+=${animationSettings.top}`;
        }
        if (animation.type === 'moveXY') {
          animationSettings.top = `+=${animationSettings.top}`;
          animationSettings.left = `+=${animationSettings.left}`;
        }
        if (animation.type === 'resizeXY') {
          animationSettings.top = `+=${animationSettings.top}`;
          animationSettings.left = `+=${animationSettings.left}`;
        }
        if (animation.type === 'resizeXY') {
          // for this animation we need the updated settings
          updatedSettings = {
            ...updatedSettings,
            duration: parseFloat(animation.duration),
            ease: `${animation.ease}.${animation.easeType}`
          };
        } else {
          updatedSettings = {
            ...animationSettings,
            duration: parseFloat(animation.duration),
            ease: `${animation.ease}.${animation.easeType}`
          };
        }
      }
      if (
        animation.type !== 'pulse' &&
        animation.ease &&
        (animation.ease === 'none' || animation.ease.includes('steps'))
      ) {
        updatedSettings = {
          ...updatedSettings,
          ease: `${animation.ease}`
        };
      }
      if (animation.type === 'background') {
        updatedSettings = {
          ...updatedSettings,
          background: `${updatedSettings.background} center no-repeat`
        };
      }
      const element = document.getElementById(`layer-${animation.target}`);

      if (animation.type !== 'makeVisible') {
        // We use this to make move and rotate animation consistend if the layer itself is rotated
        if (layer.format.rotation !== 0) {
          if (
            // if the layer is rotated and has also a move animation we add the animation to grandparent
            animation.type === 'moveX' ||
            animation.type === 'moveY' ||
            animation.type === 'moveXY'
          ) {
            const elementGrandparent = element.parentElement.parentElement;
            if (animation.direction === 'to' && timeline) {
              timeline.to(elementGrandparent, updatedSettings, animation.time);
            }
            if (animation.direction === 'from' && timeline) {
              timeline.from(elementGrandparent, updatedSettings, animation.time);
            }
            // if the layer is rotated and has also a rotate animation we add the animation to the parent
          } else if (animation.type === 'rotate') {
            const elementParent = element.parentElement;
            if (animation.direction === 'to' && timeline) {
              timeline.to(elementParent, updatedSettings, animation.time);
            }
            if (animation.direction === 'from' && timeline) {
              timeline.from(elementParent, updatedSettings, animation.time);
            }
            // if the layer is rotated but the animation isn't move or rotate
          } else {
            if (animation.direction === 'to' && timeline) {
              timeline.to(element, updatedSettings, animation.time);
            }
            if (animation.direction === 'from' && timeline) {
              timeline.from(element, updatedSettings, animation.time);
            }
          }
        } else {
          if (animation.direction === 'to' && timeline) {
            timeline.to(element, updatedSettings, animation.time);
          }
          if (animation.direction === 'from' && timeline) {
            timeline.from(element, updatedSettings, animation.time);
          }
        }
      }
      if (animation.type === 'makeVisible') {
        if (animation.direction === 'to' && timeline) {
          timeline.set(element, { visibility: 'hidden' }, animation.time);
          const animationEndTime = parseFloat(animation.time) + parseFloat(animation.duration);
          if (animationEndTime < animationsDuration) {
            timeline.set(element, { visibility: 'visible' }, animationEndTime);
          }
        }
        if (animation.direction === 'from' && timeline) {
          timeline.set(element, { visibility: 'visible' }, animation.time);
          const animationEndTime = parseFloat(animation.time) + parseFloat(animation.duration);
          if (animationEndTime < animationsDuration) {
            timeline.set(element, { visibility: 'hidden' }, animationEndTime);
          }
        }
      }

      if (animation.only_on_repeat) {
        endTweens.push(timeline.recent());
      }
      // add the same animations to the overlay element if clicktag is present
      const overlayElement = document.getElementById(`overlay-${animation.target}`);
      if (overlayElement) {
        const overlaySettings = { ...updatedSettings };
        if (animation.type === 'resize') {
          overlaySettings.width = updatedSettings.width - 1;
          overlaySettings.height = updatedSettings.height - 1;
        }
        if (animation.type === 'resizeY') {
          overlaySettings.height = updatedSettings.height - 1;
        }
        if (animation.type === 'resizeX') {
          overlaySettings.width = updatedSettings.width - 1;
        }
        if (animation.type === 'resizeXY') {
          overlaySettings.width = updatedSettings.width - 1;
          overlaySettings.height = updatedSettings.height - 1;
        }

        updatedSettings = {
          ...overlaySettings
        };
        if (
          animation.direction === 'to' &&
          animation.type !== 'opacity' &&
          animation.type !== 'makeVisible' &&
          timeline
        ) {
          timeline.to(overlayElement, updatedSettings, animation.time);
        }
        if (
          animation.direction === 'from' &&
          animation.type !== 'opacity' &&
          animation.type !== 'makeVisible' &&
          timeline
        ) {
          timeline.from(overlayElement, updatedSettings, animation.time);
        }
        if (animation.type !== 'makeVisible') {
          if (animation.direction === 'to' && timeline) {
            timeline.to(overlayElement, updatedSettings, animation.time);
          }
          if (animation.direction === 'from' && timeline) {
            timeline.from(overlayElement, updatedSettings, animation.time);
          }
        }
        if (animation.type === 'makeVisible') {
          if (animation.direction === 'to' && timeline) {
            timeline.set(overlayElement, { visibility: 'hidden' }, animation.time);
            const animationEndTime = parseFloat(animation.time) + parseFloat(animation.duration);
            if (animationEndTime < animationsDuration) {
              timeline.set(overlayElement, { visibility: 'visible' }, animationEndTime);
            }
          }
          if (animation.direction === 'from' && timeline) {
            timeline.set(overlayElement, { visibility: 'visible' }, animation.time);
            const animationEndTime = parseFloat(animation.time) + parseFloat(animation.duration);
            if (animationEndTime < animationsDuration) {
              timeline.set(overlayElement, { visibility: 'hidden' }, animationEndTime);
            }
          }
        }
        if (animation.only_on_repeat) {
          endTweens.push(timeline.recent());
        }
      }
    }
  }
  timeline.repeat(parseInt(repeats));
  timeline.set({}, {}, moveEndHandleX);

  yield fork(addTimelineEventListeners, timeline, animation, presentLayers);

  timeline.play();
}

export function* handleStopAnimations() {
  const animation = yield select(selectAnimation);
  const timeline = yield select(selectTimeline);

  if (!timeline.isActive()) {
    timeline.restart();
    timeline.play();
  }

  timeline.clear();
  yield put(resetSelectedRow());

  yield put(setAnimation({ ...animation, isPlaying: false, isPaused: false }));
}

export function* handlePauseAnimations() {
  const animation = yield select(selectAnimation);
  const timeline = yield select(selectTimeline);

  // Is playing and is not already paused.
  if (animation.isPlaying && !animation.isPaused) {
    yield put(setAnimation({ ...animation, isPaused: true }));
    timeline.pause();
  }
}

export function* handlePlayAnimations() {
  const animation = yield select(selectAnimation);
  const timeline = yield select(selectTimeline);

  if (!animation.isPaused && !animation.isPlaying) {
    yield call(handleStopAnimations);
    yield call(handleBuildAnimations);
  } else if (animation.isPlaying && animation.isPaused) {
    yield put(setAnimation({ ...animation, isPlaying: true, isPaused: false }));
    timeline.play();
  }
}

export function* handleChangeAnimation() {
  const animation = yield select(selectAnimation);
  const timeline = yield select(selectTimeline);
  const presentLayers = yield select(selectPresentLayers);
  const clicktagPreviewData = yield select(selectClicktagPreviewData);

  if (!animation.isPlaying) {
    yield call(handleResetAnimations, timeline, animation, presentLayers, clicktagPreviewData);
  }
}

// this is for updating the selected format on clicktag changes
// export function* handleSetTemplateClicktag() {
//   const selectedFormatt = yield select(selectedFormat);
//   const template = yield select(selectTemplate);
//   console.log('selectedFormatt: ', selectedFormatt)
//   console.log('template: ', template)
//   const format = template.formats.find((format) => format.id === selectedFormatt.id)

//   console.log('format: ', format)
//   yield put(selectFormat(format));
// }

function* watchTemplate() {
  yield takeLatest(getTemplate, handleGetTemplate);

  yield takeEvery(selectFormat, handleRerenderLayers);

  // TODO: General way to do this?
  yield takeLatest(addLayer, handleRerenderLayers);
  yield takeLatest(addGroupLayer, handleRerenderLayers);
  yield takeLatest(pasteLayer, handleRerenderLayers);
  yield takeLatest(deleteLayer, handleRerenderLayers);
  yield takeLatest(updateLayerVideo, handleRerenderLayers);
  yield takeLatest(undo, handleRerenderLayers);
  yield takeLatest(redo, handleRerenderLayers);
  yield takeLatest(updateLayerDCO, handleRerenderLayers);
  yield takeLatest(setTemplateDCO, handleRerenderLayers);
  yield takeLatest(addAnimation, handleRerenderLayers);
  yield takeLatest(deleteAnimation, handleRerenderLayers);
  yield takeLatest(updateAnimation, handleRerenderLayers);
  yield takeLatest(updateLayerName, handleRerenderLayers);
  yield takeLatest(reorderLayers, handleRerenderLayers);
  yield takeLatest(updateLayerSettings, handleRerenderLayers);
  yield takeLatest(updateLayerPosition, handleRerenderLayers);
  yield takeLatest(updateLayerFormat, handleRerenderLayers);
  yield takeLatest(updateFormatLayerSettings, handleRerenderLayers);
  yield takeLatest(updateLayerEditorSettings, handleRerenderLayers);
  yield takeLatest(updateLayerFont, handleRerenderLayers);
  yield takeLatest(updateLayerImage, handleRerenderLayers);
  yield takeLatest(updateLayerImageState, handleRerenderLayers);
  yield takeLatest(updateLayerImageScale, handleRerenderLayers);
  yield takeLatest(loadImages, handleRerenderLayers);
  yield takeLatest(setOverwriteImageBase64Data, handleRerenderLayers);
  yield takeLatest(setImageBase64Data, handleRerenderLayers);
  yield takeLatest(duplicateFormatImageBase64Data, handleRerenderLayers);
  yield takeLatest(duplicateLayerImageBase64Data, handleRerenderLayers);
  yield takeLatest(addImage, handleRerenderLayers);

  // TODO: Create an editorSessionSaga
  yield takeEvery(playAnimations, handlePlayAnimations);
  yield takeEvery(pauseAnimations, handlePauseAnimations);
  yield takeEvery(stopAnimations, handleStopAnimations);
  yield takeEvery(buildAnimations, handleBuildAnimations);
  yield takeEvery(setAnimation, handleChangeAnimation);

  yield takeEvery(updateLayerImageState, handleUpdateLayerImageState);
  yield takeEvery(undo, handleRedoUndo);
  yield takeEvery(redo, handleRedoUndo);
  yield takeEvery(saveAd, handleSaveAd);

  // yield takeEvery(setTemplateClicktag, handleSetTemplateClicktag);
}

export default watchTemplate;

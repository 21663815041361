import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTemplateRepeatFrom,
  setTemplateRepeats,
  setTemplateRotateOnRepeat
} from '../../redux/slices/template';

import InputNumber from '../common/InputNumber';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  StyledInputLabelWrapper,
  StyledInputWrapperBig,
  StyledSelectWide,
  StyledCheckbox
} from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';

function RightMenuTemplateSettings({ onSetTemplateDuration }) {
  const rotateOnRepeat = useSelector((state) => state.template.object_data.settings.rotateOnRepeat);
  const editorType = useSelector((state) => state.editorSession.editorType);

  const timelineRepeatFrom = useSelector((state) =>
    'repeatFrom' in state.template.object_data.settings
      ? state.template.object_data.settings.repeatFrom
      : 0
  );
  const timelineDuration = useSelector((state) => state.template.object_data.settings.duration);
  const repeats = useSelector((state) => state.template.object_data.settings.repeats);
  const dcoSelection = useSelector((state) => state.dco.dcoSelection);
  const [formSection, setFormSection] = useState({
    templateSettingsOpened: true,
    dynamicOpened: true
  });
  const capturePosthogData = usePosthogCapture();
  const dispatch = useDispatch();

  const [numberOfRepeats, setNumberOfRepeats] = useState(['0', '1', '2', '3']);
  useEffect(() => {
    setNumberOfRepeats(
      dcoSelection.dynamicSourceIncluded || dcoSelection.parameterSourceIncluded
        ? ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
        : ['0', '1', '2', '3']
    );
  }, [dcoSelection]);

  return (
    <>
      <ToggleRevealSectionButton
        id='right-submenu-edit-open-close-template-settings-section'
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'template',
            menu: 'right',
            menu_type: 'template',
            submenu: 'template settings',
            is_open: !formSection.templateSettingsOpened
          });
          setFormSection({
            ...formSection,
            templateSettingsOpened: !formSection.templateSettingsOpened
          });
        }}
        isOpened={formSection.templateSettingsOpened}>
        <p className='color-white'>Template Settings</p>
      </ToggleRevealSectionButton>
      {formSection.templateSettingsOpened && (
        <div className='template-settings-section'>
          <StyledInputLabelWrapper templateSettings>
            <p>Loops:</p>
            <StyledSelectWide
              value={repeats || 0}
              style={{ marginBottom: 0 }}
              onChange={(e) => dispatch(setTemplateRepeats(e.target.value))}>
              {numberOfRepeats.map((repeatTime) => (
                <option value={repeatTime} key={repeatTime}>
                  {repeatTime}
                </option>
              ))}
            </StyledSelectWide>
          </StyledInputLabelWrapper>
          <StyledInputLabelWrapper templateSettings>
            <p>Loop from:</p>
            <StyledInputWrapperBig fullWidth>
              <InputNumber
                leftAligned
                id='repeat-at'
                min={0}
                step={0.1}
                max={timelineDuration - 0.1}
                defaultValue={timelineRepeatFrom || 0}
                withDecimal
                value={timelineRepeatFrom || 0}
                onUpdate={(value) => dispatch(setTemplateRepeatFrom(parseFloat(value)))}
              />
            </StyledInputWrapperBig>
          </StyledInputLabelWrapper>
          <StyledInputLabelWrapper templateSettings>
            <p>Loop at:</p>
            <StyledInputWrapperBig fullWidth>
              <InputNumber
                id='repeat-at'
                leftAligned
                min={0}
                step={0.1}
                defaultValue={timelineDuration || 0}
                withDecimal
                value={timelineDuration || 0}
                onUpdate={(value) => {
                  onSetTemplateDuration(value);
                }}
              />
            </StyledInputWrapperBig>
          </StyledInputLabelWrapper>
        </div>
      )}
      {(dcoSelection.dynamicSourceIncluded || dcoSelection.parameterSourceIncluded) &&
        editorType === 'template' && (
          <>
            {' '}
            <ToggleRevealSectionButton
              id='right-submenu-edit-open-close-template-settings-section'
              handleOnClick={() => {
                capturePosthogData({
                  event: 'toggle-open-close-menu',
                  type: 'dynamic-rotate-on-repeat',
                  menu: 'right',
                  menu_type: 'template',
                  submenu: 'template settings',
                  is_open: !formSection.dynamicOpened
                });
                setFormSection({
                  ...formSection,
                  dynamicOpened: !formSection.dynamicOpened
                });
              }}
              isOpened={formSection.dynamicOpened}>
              <p className='color-white'>Dynamic</p>
            </ToggleRevealSectionButton>
            {formSection.dynamicOpened && (
              <StyledSectionWrap>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledCheckbox
                    id='rotate-product-on-repeat'
                    type='checkbox'
                    data-cy='assign-clicktag-checkbox'
                    checked={rotateOnRepeat}
                    onChange={(e) => dispatch(setTemplateRotateOnRepeat(e.target.checked))}
                  />
                  <p style={{}} className='input-text'>
                    Rotate product on repeat
                  </p>
                </div>
              </StyledSectionWrap>
            )}
          </>
        )}
    </>
  );
}

export default RightMenuTemplateSettings;

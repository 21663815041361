/* eslint-disable import/prefer-default-export */
import { get, post, deleteCall } from './basicCalls';

const IMAGE_UPLOAD_URL = window._env_.REACT_ENV_IMAGE_UPLOAD_URL;

async function getImages(templateId, campaignId, clientId = null) {
  return get(`${IMAGE_UPLOAD_URL}/${templateId}/images`, campaignId, clientId);
}

async function uploadImages(data, campaignId, clientId = null) {
  return post(`${IMAGE_UPLOAD_URL}/images`, data, campaignId, clientId);
}

async function deleteImageFromTemplate(imageId, templateId, campaignId = null, clientId = null) {
  return deleteCall(`${IMAGE_UPLOAD_URL}/${templateId}/images/${imageId}`, campaignId, clientId);
}
async function deleteImageFromCampaign(imageId, campaignId, clientId = null) {
  return deleteCall(
    `${IMAGE_UPLOAD_URL}/campaign/${campaignId}/images/${imageId}`,
    campaignId,
    clientId
  );
}

async function duplicateImage(oldTemplateId = null, newTemplateId = null, clientId = null) {
  return post(`${IMAGE_UPLOAD_URL}/images/${oldTemplateId}/${newTemplateId}/duplicate`, clientId);
}

export {
  getImages,
  uploadImages,
  deleteImageFromTemplate,
  deleteImageFromCampaign,
  duplicateImage
};

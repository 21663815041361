import React from 'react';
import './DCOTable.scss';
import { useTable, usePagination } from 'react-table';

import { LeftArrowIcon, RightArrowIcon } from '../../assets/icons';

export default function DCOTable(props) {
  const { data, allData, setAllData, size, type, isError = true, googleStudio = false } = props;

  function calculateNumber(number) {
    return number % size;
  }

  function containsObject(number, list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].row === calculateNumber(number)) {
        return true;
      }
    }
    return false;
  }

  const columns = React.useMemo(
    () =>
      !googleStudio
        ? [
            {
              Header: 'IMPORT',
              accessor: 'import',
              headStyle: { paddingRight: 30 },
              cellStyle: { paddingLeft: 10 }
            },
            {
              Header: 'FIELD NAME',
              accessor: 'key',
              className: 'column-name',
              headStyle: { paddingRight: 30 }
            },
            {
              Header: 'COLUMN CONTENT',
              accessor: 'value'
            }
          ]
        : [
            {
              Header: 'FEED NAME',
              accessor: 'feed',
              className: 'feed-name',
              cellStyle: { width: 75, paddingRight: 75 }
            },
            {
              Header: 'COLUMN NAME',
              accessor: 'key',
              className: 'column-name',
              cellStyle: { width: 75, paddingRight: 75 }
            },
            {
              Header: 'COLUMN EXAMPLE',
              accessor: 'value'
            }
          ],
    []
  );

  const paramColumns = React.useMemo(
    () => [
      {
        Header: 'URL parameter',
        accessor: 'parameter',
        className: 'bold'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'From url',
        accessor: 'example'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns: type === 'content' ? columns : paramColumns,
      data,
      isError,
      initialState: { pageIndex: 0, pageSize: size, autoResetSelectedRows: false }
    },
    usePagination
  );

  const getCorrectCellValue = (cell) => {
    if (cell.column.id === 'key' && googleStudio) {
      return cell.value.split(/\[\d\]\./i)[1];
    }

    return cell.render('Cell');
  };

  return (
    <>
      {type === 'content' && !googleStudio && (
        <div
          style={{
            marginBottom: '10px',
            fontSize: '13px',
            fontWeight: 300,
            color: 'rgba(255, 255, 255, 0.7)',
            top: '-35px',
            right: '0px',
            position: 'absolute'
          }}>
          {pageIndex + 1} of {pageOptions.length}
          <button
            type='button'
            style={{ marginRight: '5px', marginLeft: '15px' }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}>
            <LeftArrowIcon opacity={!canPreviousPage ? 0.2 : 1.0} />
          </button>
          <button
            type='button'
            style={{ marginLeft: '5px' }}
            onClick={() => nextPage()}
            disabled={!canNextPage}>
            <RightArrowIcon opacity={!canNextPage ? 0.2 : 1.0} />
          </button>
        </div>
      )}

      <div
        id='dco-table'
        className='main-table'
        style={isError ? { border: '1px solid #ff4545' } : {}}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps({ style: column.headStyle && column.headStyle })}
                    key={i}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i) => (
                    <td
                      {...cell.getCellProps([
                        { className: cell.column.className },
                        { style: cell.column.cellStyle && cell.column.cellStyle }
                      ])}
                      key={i}>
                      {cell.value && getCorrectCellValue(cell)}
                      {cell.column.id === 'import' && (
                        <div>
                          <input
                            type='checkbox'
                            checked={
                              containsObject(cell.row.index, allData.selected_rows) && 'checked'
                            }
                            onChange={() => {
                              if (containsObject(cell.row.index, allData.selected_rows)) {
                                setAllData({
                                  ...allData,
                                  selected_rows: [
                                    ...allData.selected_rows.filter(
                                      (elm) => elm.row !== calculateNumber(cell.row.index)
                                    )
                                  ]
                                });
                              } else {
                                setAllData({
                                  ...allData,
                                  selected_rows: [
                                    ...allData.selected_rows,
                                    { row: calculateNumber(cell.row.index) }
                                  ]
                                });
                              }
                            }}
                          />
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

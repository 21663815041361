import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { saveTemplate } from '../../redux/slices/template';

import BaseButton from '../common/BaseButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { StyledInputLabelWrapper } from '../common/styled/AttributeInputs';

function PublishTemplateButton() {
  const dispatch = useDispatch();
  const template = useSelector((state) => state.template);

  const isDisabled = template === undefined || template.id === undefined;
  const capturePosthogData = usePosthogCapture();

  const handlePublishTemplate = async () => {
    capturePosthogData({ event: 'save-and-publish', type: 'template', menu: 'right' });
    dispatch(saveTemplate({ published: true }));
    toast.loading('Publishing template ...', {
      id: 'template-published'
    });
  };

  return (
    <StyledInputLabelWrapper>
      <p>Publish to Zuuvi Manager:</p>
      <BaseButton
        id='save-publish-template'
        text='Save and Publish'
        style={{ backgroundColor: '#4792E2', color: 'white', marginTop: 7 }}
        disabled={isDisabled}
        onClick={handlePublishTemplate}
      />
    </StyledInputLabelWrapper>
  );
}

export default PublishTemplateButton;

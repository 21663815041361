/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { BackArrowIcon, CloseIcon } from '../../assets/icons';
import { useClickOutside } from '../../assets/utils';
import {
  resetActiveMenu,
  resetSourceData,
  resetUserInput,
  setActiveMenu
} from '../../redux/slices/dco';
import { setLeftMenu } from '../../redux/slices/editorSession';

const Background = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 150;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  left: ${(props) => (props.size === 'small' ? '480px' : '1000px')};
  height: 20px;
  width: 20px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  height: 20px;

  font-size: 13px;
  line-height: 16px;
`;

const BackIcon = styled(BackArrowIcon)`
  width: 15.4px;
  height: 10.1px;
  margin-right: 5px;
`;

const MenuWrapper = styled.div`
  background: ${(props) => props.theme.colors.backgroundColorSecondary};
  border-right: 1px solid #353535;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  width: ${(props) => (props.size === 'small' ? '520px' : '1040px')};
  height: 100vh;

  display: flex;
  flex-direction: column;

  .child {
    &.last {
      flex-grow: 1;
    }
  }

  z-index: 201;
`;

const HeaderWrapper = styled.div`
  width: auto;
  height: 70px;

  left: 0;
  right: 0;
  padding: 20px 35px;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const ToolbarWrapper = styled.div`
  height: 30px;
  padding: 15px 35px;
  border-top: 1px solid #353535;
  border-bottom: 1px solid #353535;
`;

const ContentWrapper = styled.div`
  padding: 25px 35px;
  height: ${(props) => (props.toolbar ? 'calc(100% - 105px - 60px)' : 'calc(100% - 105px)')};
  overflow: ${(props) => (props.scroll ? 'scroll' : 'hidden')};
`;

function Menu(props) {
  const { Header, Toolbar, Content, size, modalRef } = props;

  const { leftMenu } = useSelector((state) => state.editorSession.menus);
  const { activeMenu } = useSelector((state) => state.dco);
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const menuRef = useRef();

  const closeMenu = () => {
    dispatch(resetUserInput());
    dispatch(resetSourceData());
    dispatch(resetActiveMenu());
    dispatch(
      setLeftMenu({
        ...leftMenu,
        dynamicLargeMenu: ''
      })
    );
  };

  useClickOutside(menuRef, () => closeMenu(), [modalRef]);

  return (
    <Background>
      <MenuWrapper ref={menuRef} size={size}>
        <HeaderWrapper>
          <CloseButton
            size={size}
            id='dco-menu-close-button'
            className='close-button'
            onClick={() => closeMenu()}>
            <CloseIcon />
          </CloseButton>
          {(activeMenu.menu === 'create' || activeMenu.menu === 'configure') && (
            <BackButton
              id='dco-menu-back-button'
              className='back-button'
              onClick={() => {
                capturePosthogData({ event: `go-back-button-${activeMenu.menu}`, type: 'dco' });
                if (leftMenu.dynamicLargeMenu === 'dpa') {
                  dispatch(setActiveMenu({ ...activeMenu, menu: 'overview', isError: false }));
                } else if (activeMenu.menu === 'configure') {
                  dispatch(setActiveMenu({ ...activeMenu, menu: 'create', isError: false }));
                } else {
                  dispatch(resetActiveMenu());
                }
              }}>
              <BackIcon />
              Back
            </BackButton>
          )}
          <Header />
        </HeaderWrapper>
        {Toolbar && (
          <ToolbarWrapper>
            <Toolbar />
          </ToolbarWrapper>
        )}
        <ContentWrapper
          toolbar={!!Toolbar}
          scroll={
            activeMenu.source === 'link' ||
            activeMenu.source === 'file' ||
            activeMenu.source === 'parameter' ||
            activeMenu.source === 'dpa' ||
            activeMenu.source === 'raptor' ||
            activeMenu.source === 'google-studio'
          }>
          <Content />
        </ContentWrapper>
      </MenuWrapper>
    </Background>
  );
}

Menu.defaultProps = {
  Toolbar: null,
  size: 'small'
};

Menu.propTypes = {
  Header: PropTypes.func.isRequired,
  Toolbar: PropTypes.func,
  Content: PropTypes.func.isRequired,
  size: PropTypes.string,
  modalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default Menu;

/* eslint-disable import/prefer-default-export */
import { get, post } from './basicCalls';

const LOAD_AD_URL = window._env_.REACT_ENV_LOAD_AD_LAMBDA_URL;
const SAVE_AD_URL = window._env_.REACT_ENV_SAVE_AD_LAMBDA_URL;
const PUBLISH_AD_URL = window._env_.REACT_ENV_PUBLISH_AD_URL;

async function getAd(adId) {
  return get(`${LOAD_AD_URL}/load_ad/${adId}`);
}

async function saveAd(adId, data) {
  return post(`${SAVE_AD_URL}/save_ad/${adId}`, data);
}

async function publishAd(adId) {
  return post(`${PUBLISH_AD_URL}/publish_ad/${adId}`);
}

export { getAd, saveAd, publishAd };

/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import AutosizeInput from 'react-input-autosize';

import PropTypes from 'prop-types';
import {
  addParameterRow,
  createDynamicSource,
  resetActiveMenu,
  setActiveMenu,
  setUserInput,
  createParameterSource
} from '../../redux/slices/dco';
import Menu from './Menu';
import { GreenCheckmarkIcon } from '../../assets/icons';

import { createDpaSource, setContinueWithInvalidRowsChecked } from '../../redux/slices/dpa';
import { StyledCheckbox } from '../common/styled/AttributeInputs';
import { getDynamicOptions, getDynamicSources } from './Helpers';
import theme from '../../assets/theme';

const HeadlineWrapper = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;

  text-align: center;

  font-size: 30px;
  font-weight: 300;
  line-height: 30px;

  color: #ffffff;

  input[type='text'] {
    background: transparent;
    border: none;
    outline: none;
  }
`;

const ContentWrapper = styled.div`
  height: auto;
  margin-bottom: 10px;
  padding: 20px 20px 20px 20px;

  background: #2b2b2b;
  border: 1px solid #353535;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`;

const ContentHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
`;

const ContentOptionWrapper = styled.div`
  text-align: ${(props) => (props.optionId === 'map-data' ? 'left' : 'center')};
  position: relative;
  margin: 20px 0px 20px;
`;

const ContentSubtitleWrapper = styled.span`
  font-size: 12px;
  margin-top: 5px;
`;

const ContentButtonWrapper = styled.div`
  position: relative;
  text-align: center;
`;

const ContentDpaInvalidWrapper = styled.div`
  right: 10px;
  position: absolute;
  display: inline-block;
  text-align: left;
  font-size: 12px;
`;

const AddParameterButton = styled.button`
  background: ${(props) => props.theme.colors.dcoPurple};
  font-size: 13px;
  color: white;
  padding: 7px 20px 7px;
  border-radius: 5px;

  display: inline-flex;
`;

const NextOptionButton = styled.button`
  width: 188px;
  height: 33px;
  font-size: 12px;
  background: #b049ef;
  border-radius: 4px;
  margin: 0px 5px 0px;
`;

const FinishOptionButton = styled.button`
  width: 188px;
  height: 33px;
  font-size: 12px;
  background: ${(props) => (props.disabled ? '#363636' : props.theme.colors.dcoPurple)};
  color: ${(props) => (props.disabled ? '#828282' : 'none')};
  border: ${(props) => (props.disabled ? '1px solid #828282' : 'none')};
  border-radius: 4px;
  margin: 0px 5px 0px;
`;

const PreviousOptionButton = styled.button`
  width: 188px;
  height: 33px;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: #3e3e3e;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 5px 0px;
`;

function Header() {
  const { activeMenu, userInput } = useSelector((state) => state.dco);

  return getDynamicSources().map(
    (data) =>
      activeMenu.source === data.id && (
        <HeadlineWrapper>
          <data.icon
            strokeArrow={theme.colors.dcoPurple}
            style={{
              height: '30px',
              width: '30px',
              marginRight: '10px',
              verticalAlign: 'middle'
            }}
          />
          <AutosizeInput
            inputStyle={{ fontSize: 30, color: 'white' }}
            disabled
            type='text'
            id='name'
            value={userInput.name}
          />
        </HeadlineWrapper>
      )
  );
}

function Content() {
  const dispatch = useDispatch();

  const { activeMenu, userInput } = useSelector((state) => state.dco);

  const { mappings, continueWithInvalidRowsChecked } = useSelector((state) => state.dpa);

  // get array of finished mapped spa rows
  function getMappedDpaArr() {
    return mappings.filter((item) => item.row_status !== null);
  }

  function getInvalidDpaRowsAmount() {
    const mappedDataArr = getMappedDpaArr();
    let invalidItems = [];
    if (mappedDataArr.length === mappings.length) {
      invalidItems = mappedDataArr.filter((item) => item.row_status.invalid_rows !== 0);
    }
    return invalidItems;
  }

  function changeOption(change) {
    dispatch(
      setActiveMenu({
        ...activeMenu,
        activeOptionNumber: activeMenu.activeOptionNumber + change,
        isError: false
      })
    );
  }

  const handleAddParameterRow = () => {
    dispatch(addParameterRow());
  };

  function finishOption() {
    switch (activeMenu.source) {
      case 'file':
      case 'link':
        dispatch(createDynamicSource());
        break;
      case 'parameter':
        dispatch(createParameterSource());
        break;
      case 'virtual':
        dispatch(resetActiveMenu());
        break;
      case 'dpa':
        dispatch(createDpaSource());
        break;
      case 'raptor':
        dispatch(setUserInput({ ...userInput, addon: 'raptor' }));
        dispatch(createDynamicSource());
        break;
      case 'google-studio':
        dispatch(createDynamicSource());
        break;
      default:
        break;
    }
  }

  function getIsFinishButtonDisabled(option) {
    let isDisabled;
    if (option.id === 'map-data') {
      if (getMappedDpaArr().length !== mappings.length) {
        isDisabled = true;
      }
      if (
        getMappedDpaArr().length === mappings.length &&
        getInvalidDpaRowsAmount().length !== 0 &&
        !continueWithInvalidRowsChecked
      ) {
        isDisabled = true;
      }
      if (getMappedDpaArr().length === mappings.length && getInvalidDpaRowsAmount().length === 0) {
        isDisabled = false;
      }
    }
    return isDisabled;
  }

  function DCOButtons(nextFunction, disabled) {
    return {
      next: (
        <NextOptionButton
          id='dco-option-next-button'
          onClick={() => (nextFunction ? nextFunction(1) : changeOption(1))}>
          Next step
        </NextOptionButton>
      ),
      previous: (
        <PreviousOptionButton id='dco-option-previous-button' onClick={() => changeOption(-1)}>
          Back
        </PreviousOptionButton>
      ),
      finish: (
        <FinishOptionButton
          disabled={disabled || false}
          id='dco-option-finish-button'
          onClick={() => finishOption()}>
          Finish
        </FinishOptionButton>
      )
    };
  }

  const filteredDynamicConfigurationOptions = getDynamicOptions().filter((option) =>
    option.active.includes(activeMenu.source)
  );

  return filteredDynamicConfigurationOptions.map((option, index) => (
    <ContentWrapper key={`content-${index}`}>
      <ContentHeaderWrapper>
        <div
          style={{
            color: index <= activeMenu.activeOptionNumber ? 'white' : '#3E3E3E',
            display: 'flex',
            alignItems: 'center'
          }}>
          <p> {option.name}</p>
          {activeMenu.activeOptionNumber > index && (
            <div style={{ marginLeft: 7, display: 'flex', alignItems: 'center' }}>
              <GreenCheckmarkIcon />
            </div>
          )}
        </div>
        <div>
          {option.id === 'choose-parameters' && activeMenu.activeOptionNumber === index && (
            <>
              <AddParameterButton id='dco-option-add-parameter-row' onClick={handleAddParameterRow}>
                Add parameter
              </AddParameterButton>
            </>
          )}
        </div>
      </ContentHeaderWrapper>

      {activeMenu.activeOptionNumber === index && (
        <>
          {option.subtitle && <ContentSubtitleWrapper>{option.subtitle}</ContentSubtitleWrapper>}
          <ContentOptionWrapper id='option-content-wrapper' optionId={option.id}>
            {option.content}
          </ContentOptionWrapper>
          <ContentButtonWrapper>
            {index !== 0 && DCOButtons().previous}
            {index !== filteredDynamicConfigurationOptions.length - 1 &&
              DCOButtons(option.nextFunction).next}
            {index === filteredDynamicConfigurationOptions.length - 1 &&
              DCOButtons(null, getIsFinishButtonDisabled(option)).finish}
            {option.id === 'map-data' &&
              getMappedDpaArr().length === mappings.length &&
              getInvalidDpaRowsAmount().length !== 0 && (
                <ContentDpaInvalidWrapper>
                  <div>There are several rows with errors.</div>
                  <div style={{ display: 'flex', marginTop: 3 }}>
                    <div style={{ marginRight: 6 }}>Continue anyway?</div>{' '}
                    <StyledCheckbox
                      id='dpa-invalid-rows-checkbox'
                      data-cy='dpa-invalid-rows-checkbox'
                      type='checkbox'
                      checked={continueWithInvalidRowsChecked}
                      onChange={() => {
                        dispatch(
                          setContinueWithInvalidRowsChecked(!continueWithInvalidRowsChecked)
                        );
                      }}
                    />
                  </div>
                </ContentDpaInvalidWrapper>
              )}
          </ContentButtonWrapper>
        </>
      )}
    </ContentWrapper>
  ));
}

function ConfigureMenu(props) {
  const { modalRef } = props;
  return <Menu size='large' Header={Header} Content={Content} modalRef={modalRef} />;
}

ConfigureMenu.defaultProps = {};

ConfigureMenu.propTypes = {
  modalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default ConfigureMenu;

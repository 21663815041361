import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import ZuuviNestable from '@zuuvi/zuuvi-nestable';
import { Rnd } from 'react-rnd';
import { useDispatch, useSelector } from 'react-redux';
import TimelineLayer from './TimelineLayer';
import { AddGroupIcon, RepeatFromIcon } from '../../assets/icons';
import { getDefaultLayerObject } from '../../objectsTypes';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  addLayer,
  reorderLayers,
  setTemplateDuration,
  setTemplateRepeatFrom,
  updateLayerName
} from '../../redux/slices/template';
import {
  buildAnimations,
  pauseAnimations,
  setAnimation,
  setDisplayedTimelineTime,
  setSelectedLayers,
  setTimelineTime,
  stopAnimations
} from '../../redux/slices/editorSession';

function ZuuviNestableLayers({
  isBannerEditor,
  isNestableDragDisabled,
  setIsNestableDragDisabled,
  setOpenedAnimations,
  setOpenedEffects,
  openedAnimations,
  openedEffects
}) {
  // PresentLayers, handlemultiselect, onupdatelayername, editorupdateanimation

  const dispatch = useDispatch();
  const { selectedLayers, selectedFormatId } = useSelector((state) => state.editorSession);
  const { presentLayers } = useSelector((state) => state.editorSession.studio);

  const classNames = {
    listContainer: isNestableDragDisabled ? 'nestable-container-no-overflow' : 'nestable-container',
    list: 'nestable-list',
    listItem: 'nestable-item',
    listItemContent: 'nestable-item-content',
    listItemBackground: 'nestable-item-background',
    nestedList: 'group-nested-list',
    listItemOnMultiselect: 'nestable-multiselec'
  };

  const handleMultiSelect = (layers) => {
    const layerIds = [];

    const getLayerId = (layer) => {
      if (layer.type === 'group' && layer.layers && layer.layers.length > 0) {
        // eslint-disable-next-line no-use-before-define
        getLayerIds(layer.layers);
      } else if (layer.id) {
        layerIds.push(layer.id);
      }
    };

    // eslint-disable-next-line no-shadow
    const getLayerIds = (layers) => {
      layers.forEach((layer) => {
        getLayerId(layer);
      });
    };

    getLayerIds(layers);
    dispatch(setSelectedLayers(layerIds));
  };

  return (
    <ZuuviNestable
      maxDepth={2}
      classNames={classNames}
      threshold={20}
      items={presentLayers}
      isDisabled={isBannerEditor || isNestableDragDisabled}
      renderCollapseIcon={({ isCollapsed }) =>
        isCollapsed ? (
          <span className='iconCollapse'>+</span>
        ) : (
          <span className='iconCollapse'>-</span>
        )
      }
      confirmChange={(dragItem, parentItem) =>
        parentItem?.type === 'group' && dragItem.type !== 'group' ? true : parentItem === null
      }
      onChange={(items, dragItem, oldLayers) => {
        dispatch(reorderLayers(items, oldLayers, dragItem, selectedFormatId));
      }}
      onGroup={handleMultiSelect}
      selectedLayers={selectedLayers}
      renderItem={({ item, collapseIcon, index, realItemPath, path }) => (
        <TimelineLayer
          key={item.uuid}
          layerId={item.uuid}
          layer={item}
          layerPath={realItemPath}
          layerIndex={index}
          layers={presentLayers}
          path={path}
          onSetIsNestableDragDisabled={(status) => setIsNestableDragDisabled(status)}
          onUpdateLayerName={(value, layerId) => dispatch(updateLayerName(layerId, value))}
          collapseIcon={collapseIcon}
          onSetOpenedAnimations={(data) => setOpenedAnimations(data)}
          onSetOpenedEffects={(data) => setOpenedEffects(data)}
          openedAnimations={openedAnimations}
          openedEffects={openedEffects}
        />
      )}
    />
  );
}

function TimelineSecondMarkers(props) {
  const { timescale } = props;

  const renderDecimals = () =>
    useMemo(
      () =>
        timescale.map((second) => (
          <div key={second} className='timeline-second-container'>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                marginTop: '5px',
                align: 'center',
                width: '50px'
              }}>
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  right: second < 10 ? 1.5 : 3.8
                }}>
                {second}
              </div>

              <div
                id='timelineLine'
                style={{ width: '1px', height: '10px', backgroundColor: '#3E3E3E' }}
              />
            </div>
            <div
              id='timelineLine'
              style={{
                width: '1px',
                height: '10px',
                backgroundColor: '#3E3E3E',
                marginLeft: 0,
                alignSelf: 'flex-end'
              }}
            />
          </div>
        )),
      [timescale]
    );

  return <div className='timeline-timescale'>{renderDecimals()}</div>;
}

function TimelineTimeMarkers(props) {
  const { isBannerEditor, timelineRepeatFrom, timescale } = props;

  const dispatch = useDispatch();

  const moveEndHandleX = useSelector((state) => state.template.object_data.settings.duration);
  const { timeline, timelineTime, presentLayers } = useSelector(
    (state) => state.editorSession.studio
  );

  return (
    <>
      <div className='play-animation-wrapper' style={{ width: moveEndHandleX * 100 }}>
        <Rnd
          scale={1}
          enableResizing={false}
          size={{ height: '100%', width: 'auto' }}
          style={{ zIndex: 8 }}
          position={{
            x: timelineTime * 100,
            y: 0
          }}
          bounds='parent'
          dragAxis='x'
          onDragStart={(e, d) => {
            if (timelineTime === 0) {
              dispatch(stopAnimations());
              dispatch(buildAnimations());
              dispatch(
                setAnimation((animation) => ({
                  ...animation,
                  isPaused: true,
                  isPlaying: true
                }))
              );
              timeline.pause(d.x / 100);
            }
          }}
          onDrag={(e, d) => {
            timeline.pause(d.x / 100);
            dispatch(setDisplayedTimelineTime(d.x / 100));
          }}
          onDragStop={(e, d) => {
            if (d.x / 100 === 0) {
              dispatch(stopAnimations());
            } else {
              timeline.pause(d.x / 100);
              dispatch(setTimelineTime(d.x / 100));
            }
          }}>
          <div id='timeline-play-marker'>
            <div className='animation-start-handle' />
            <div className='animation-start-line' />
          </div>
        </Rnd>
      </div>
      <div className='end-animation-wrapper' style={{ width: (moveEndHandleX - 0.1) * 100 }}>
        <Rnd
          scale={1}
          disableDragging={isBannerEditor}
          enableResizing={false}
          size={{ height: '100%', width: 'auto' }}
          style={{ zIndex: 7 }}
          position={{
            x: timelineRepeatFrom * 100 || 0,
            y: 0
          }}
          bounds='parent'
          dragAxis='x'
          onDragStop={(e, d) => {
            dispatch(setTemplateRepeatFrom(parseFloat((d.x / 100).toFixed(1))));
          }}>
          <div
            style={{
              cursor: isBannerEditor ? 'default' : 'move',
              display: timelineRepeatFrom === 0.0 ? 'none' : 'inherit'
            }}
            id='timeline-from-marker'>
            <RepeatFromIcon className='timeline-from-handle' />
            <div className='timeline-from-line' />
          </div>
        </Rnd>
      </div>
      <div className='end-animation-wrapper' style={{ width: timescale.length * 100 }}>
        {isBannerEditor ? (
          <div
            style={{
              transform: `translate(${moveEndHandleX * 100}px, 0px)`,
              height: '100%',
              width: 'auto',
              zIndex: 7,
              position: 'absolute',
              userSelect: 'auto',
              display: 'inline-block',
              top: '0px',
              left: '0px',
              cursor: 'default'
            }}>
            <div style={{ cursor: 'default' }} id='timeline-end-marker'>
              <div className='timeline-end-handle' />
              <div className='timeline-end-line' />
            </div>
          </div>
        ) : (
          <Rnd
            scale={1}
            disableDragging={isBannerEditor}
            enableResizing={false}
            size={{ height: '100%', width: 'auto' }}
            style={{ zIndex: 7 }}
            position={{
              x: moveEndHandleX * 100,
              y: 0
            }}
            bounds='parent'
            dragAxis='x'
            onDragStop={(e, d) =>
              dispatch(
                setTemplateDuration(parseFloat((d.x / 100).toFixed(1)).toFixed(1), presentLayers)
              )
            }>
            <div style={{ cursor: 'move' }} id='timeline-end-marker'>
              <div className='timeline-end-handle' />
              <div className='timeline-end-line' />
            </div>
          </Rnd>
        )}
      </div>
    </>
  );
}

function Timeline(props) {
  const {
    timelineContainerRef,

    setOpenedAnimations,
    setOpenedEffects,
    openedAnimations,
    openedEffects
  } = props;

  const dispatch = useDispatch();
  const { rightMenu, leftMenu } = useSelector((state) => state.editorSession.menus);
  const { isLayersHidden } = useSelector((state) => state.editorSession.studio);
  const { selectedFormatId, editorType } = useSelector((state) => state.editorSession);
  const timelineRepeatFrom = useSelector((state) =>
    'repeatFrom' in state.template.object_data.settings
      ? state.template.object_data.settings.repeatFrom
      : 0
  );
  const timelineContainerLength = useSelector((state) =>
    Math.max(state.template.object_data.settings.duration * 2, 20)
  );

  const resizeTimelineContainerRef = useRef();
  const capturePosthogData = usePosthogCapture();

  const [containerHeight, setContainerHeight] = useState(400);
  const [lastHeight, setLastHeight] = useState(400);
  const [containerIsResizable, setContainerIsResizable] = useState(false);
  const [isNestableDragDisabled, setIsNestableDragDisabled] = useState(false);

  const isBannerEditor = editorType === 'banner';

  useEffect(() => {
    const mouseMoveResizeHandleHandler = (event) => {
      event.preventDefault();

      if (containerIsResizable) {
        setContainerHeight((oldHeight) =>
          oldHeight +
            (resizeTimelineContainerRef.current?.getBoundingClientRect().y - event.clientY) >
            25 &&
          oldHeight +
            (resizeTimelineContainerRef.current?.getBoundingClientRect().y - event.clientY) <
            800
            ? oldHeight +
              (resizeTimelineContainerRef.current?.getBoundingClientRect().y - event.clientY)
            : oldHeight
        );
      }
    };

    if (containerIsResizable) {
      window.addEventListener('mousemove', mouseMoveResizeHandleHandler);
    }

    return () => {
      window.removeEventListener('mousemove', mouseMoveResizeHandleHandler);
    };
  }, [containerIsResizable]);

  const showHideLayers = () => {
    if (containerHeight > 25) {
      setLastHeight(containerHeight);
      setContainerHeight(25);
    } else if (containerHeight === 25) {
      if (lastHeight > 75) {
        setContainerHeight(lastHeight);
      } else {
        setContainerHeight(400);
        setLastHeight(400);
      }
    } else {
      setContainerHeight(lastHeight);
    }
  };
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      showHideLayers();
    }
  }, [isLayersHidden]);

  function onMouseDownResizeTimelineHandler(event) {
    event.preventDefault();
    setContainerIsResizable(true);
    window.addEventListener('mouseup', function handleMouseUp() {
      setLastHeight(containerHeight);
      setContainerIsResizable(false);
      window.removeEventListener('mouseup', handleMouseUp);
    });
  }

  // Array of numbers from 0 to timelineContainerLength
  const timescale = useMemo(
    () => Array.from({ length: timelineContainerLength + 1 }, (_, index) => index),
    [timelineContainerLength]
  );

  function getNavigationStatus() {
    if (rightMenu !== '' && leftMenu.leftNavTabActive !== '') {
      return 'calc(100% - 626px)';
    }
    if (rightMenu !== '' && leftMenu.leftNavTabActive === '') {
      return 'calc(100% - 386px)';
    }
    if (rightMenu === '' && leftMenu.leftNavTabActive !== '') {
      return 'calc(100% - 376px)';
    }
    return 'calc(100% - 136px)';
  }

  const MemorizedSecondMarkers = memo(TimelineSecondMarkers);

  return (
    <div
      className='timeline-container'
      ref={timelineContainerRef}
      style={{
        height: containerHeight,
        width: getNavigationStatus(),
        left: leftMenu.leftNavTabActive !== '' ? 270 : 0,
        right: rightMenu !== '' ? 318 : 68
      }}>
      <div
        className='resize-timeline-container'
        ref={resizeTimelineContainerRef}
        onMouseDown={(e) => onMouseDownResizeTimelineHandler(e)}
      />
      <div
        className='timeline-wrapper horizontal-scroll'
        style={{
          position: 'absolute'
        }}>
        <div className='timeline-header'>
          <div>
            <div className='timeline-title'>
              <p className='text-medium color-white'>Layers</p>
              {!isBannerEditor && (
                <button
                  type='button'
                  id='timeline-add-group-layer'
                  className='add-folder'
                  onClick={() => {
                    capturePosthogData({
                      event: 'add-group-layer',
                      type: 'element',
                      menu: 'bottom-timeline'
                    });
                    dispatch(addLayer(getDefaultLayerObject('group'), selectedFormatId));
                  }}>
                  <AddGroupIcon fill='white' className='icon-hover' height={16} width={17} />
                </button>
              )}
            </div>
          </div>
          <div>
            <TimelineTimeMarkers
              isBannerEditor={isBannerEditor}
              timelineRepeatFrom={timelineRepeatFrom}
              timescale={timescale}
            />
            <MemorizedSecondMarkers timescale={timescale} />
          </div>
        </div>
        <div style={{ width: timescale.length * 100 + 196, height: 'calc(100% - 25px)' }}>
          <ZuuviNestableLayers
            isBannerEditor={isBannerEditor}
            isNestableDragDisabled={isNestableDragDisabled}
            setIsNestableDragDisabled={setIsNestableDragDisabled}
            setOpenedAnimations={setOpenedAnimations}
            setOpenedEffects={setOpenedEffects}
            openedAnimations={openedAnimations}
            openedEffects={openedEffects}
          />
        </div>
      </div>
    </div>
  );
}

Timeline.propTypes = {
  timelineContainerRef: PropTypes.object.isRequired,
  setOpenedAnimations: PropTypes.func.isRequired,
  setOpenedEffects: PropTypes.func.isRequired,
  openedAnimations: PropTypes.arrayOf(PropTypes.string).isRequired,
  openedEffects: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Timeline;
